<template>
  <v-container ref="basewindow" fluid class="d-flex flex-column pa-0 ma-0" style="height:100vh; overflow: hidden;">
    <transition name="fade">
    <v-overlay v-if="videoLoading" style="background:black;z-index:100000;">
      <v-img src="/favicon.png" height="10vh" contain/>
    </v-overlay>
    </transition>
    <v-layout class="menu pa-0 ma-0" justify-center>
      <!-- Menu Header -->
      <v-col cols="12" class="pa-0 ma-0" >
        <MenuBar @resetSubtitles="resetSubtitles" @saveProject="saveProject" @exportToJson="exportToJson"
          @exportToSrt="exportToSrt" @exportToAss="exportToAss" @exportAudiosForBwf="exportAudiosForBwf"
          @exportProject="exportProject" @importProject="importProject"
          @openExportWindow="openExportWindow" @detectSceneChange="detectSceneChange" @detectSubtitles="detectSubtitles"
          @audioDenoiser="audioDenoiser" @generateSubtitles="generateSubtitles" @generateAudio="generateAudio"
          @playVideo="playVideo" @incrementSpeedRate="incrementSpeedRate" @getAudioData="getAudioData"
          @openFriendInvite="inviteFriendModal = true"
          @openTaskPopup="taskPopup = true" @importSubtitles="importSubtitles" @openPopupPlan="openPopupPlan = $event"
          @openSettings="openSettings = $event" :lastSave="lastSave" :cinemaMode="cinemaMode"
          @cinemaModeEvent="cinemaMode = $event" @openHelpWindow="openHelpWindow = $event" :subtitles="subtitles"
          :video="$refs.video" />
        <v-row>
          <v-col>
            <v-tabs dense v-model="bottomTab">
              <v-tab>Rythmo</v-tab>
              <v-tab :disabled="!subtitles.length">Audio</v-tab>
              <v-tab :disabled="!subtitles.length">Exporter</v-tab>
            </v-tabs>
          </v-col>
          <v-col>
            <template v-if="!$vuetify.breakpoint.mobile">
              <template v-if="!saveInProgress">
                <v-btn @click="saveProject()" icon
                  :color="$moment().diff(lastSave * 1000, 'minutes') > 5 ? 'red' : 'green'">
                  <v-icon>
                    <!-- cloud sync -->
                    mdi-cloud-sync
                  </v-icon>
                </v-btn>
                <small>{{ $moment(lastSave * 1000).fromNow() }}</small>
              </template>
              <template v-else>
                <v-progress-circular v-if="saveInProgress" :value="uploadProgress" color="primary" size="20" />
                <small v-if="saveInProgress">
                  {{ $t('title.saving') }}
                </small>
              </template>
            </template>
            -
            <small>{{ title }}</small>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
    <v-dialog v-model="error" top transition="dialog-bottom-transition" max-width="600px" persistent width="100%"
      :retain-focus="false">
      <v-card dark>
        <v-card-title>
        </v-card-title>
        <v-card-text class="text-center">
          <span class="headline primary--text">{{ $t('error.title') }}</span>
          <h3> {{ $t('error.error_message') }}</h3>
          <!-- discord button -->
          <v-btn color="primary" class="mt-12" @click="window.open('https://discord.gg/RrmKMBsYFy', '_blank')">
            {{ $t('help.join_us_on_discord') }}
          </v-btn><br />
          Details<br />
          <code>
        {{ errorMessage }}
      </code>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="error = false">{{ $t('button.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="fullscreen">
      <div class="containerapp">
        <div style="overflow:hidden;" xs12 :md6="!cinemaMode" class="pa-0 ma-0"
          :class="cinemaMode ? 'center' : 'column1'">
          <div class="videocontainer">
            <!-- Video Editor -->
            <div id="videoCanvas" class="pa-0 ma-0 videocanvas">
              <v-row v-show="false">
                <v-col>
                  <label>
                    Importer une video
                    <input type="file" ref="inputVideo" @change="loadVideoBlob($event)" />
                  </label>
                </v-col>
              </v-row>
              <div class="pa-0 text-center ma-0 art-video-player" style="width: 100%;">
                <video class="art-video" :muted="isRecording"
                  style="box-shadow: #e8497c24 1px 1px 32px; width:100%;margin:auto;" crossorigin="anonymous"
                  ref="video" webkit-playsinline playsinline controls>
                  <source :src="videoSource" type="video/mp4" />
                </video>

                <!-- Display filigrane -->
              </div>
              </div>
          </div>
        </div>
        <div v-show="!cinemaMode" xs12 md6 style="overflow: scroll;" class="column2">
          <!-- Subtitle Editor -->
          <v-col class="pa-0 ma-0">
            <v-col class="mx-0 my-0 px-0 py-0">
              <v-col cols="12" v-show="false">
                <label>
                  {{ $t('options.load_subtitles') }}
                  <input type="file" ref="inputSousTitre" @change="importFromJson($event)" />
                </label>
              </v-col>
              <!-- order subtitle by last id -->
              <v-col cols="12" class="pa-0 mx-0 mt-0" :style="{
        position: 'relative'
      }">
                <v-toolbar dense elevation="0" class="fixed-bar">
                  <v-text-field outlined dense v-model="title" hide-details />
                  <v-select class="ml-2" hide-details :items="getAvailableLanguage" v-model="settings.language"
                    label="Language" outlined dense @change="changeSubtitlesLanguage">
                    <template v-slot:append>
                      <v-menu>
                        <template v-slot:activator="{ on }">
                          <v-btn small icon color="primary" v-on="on" @click.stop>
                            <v-icon small>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item v-for="i in ['fr', 'en', 'de', 'ja', 'it', 'es']"
                            @click="$auth?.user?.role != 'pro' && $auth?.user?.role != 'basic' ? openPopupPlan = true : createLanguage(i)"
                            :key="`lang${i}`">
                            {{ i }}
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-select>
                  <v-spacer />
                  <!-- Badge powered by AI -->
                  <v-badge color="error" overlap class="mr-4" v-if="false">
                    <template v-slot:badge>
                      {{ $t('terms.ai') }}
                    </template>
                    <v-btn title="Speech To Text" small elevation="0" :disabled="!checkIfBrowserIsChrome()"
                      @click="checkIfBrowserIsChrome() ? speechRecognition ? $refs.video.pause() : speechReconitionInit() : false;"
                      :color="speechRecognition ? 'red' : 'primary'">
                      <v-icon v-if="speechRecognition">
                        mdi-pause
                      </v-icon>
                      <v-icon v-else>
                        mdi-play
                      </v-icon>
                      <v-icon>
                        mdi-account-voice
                      </v-icon>
                    </v-btn>
                  </v-badge>
                  <v-btn title="Ajouter un sous-titre" fab x-small color="primary" class="ml-6" elevation="0"
                    v-if="false"
                    @click="createSubtitle('Text Here'); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                    <v-icon>mdi-text-box-plus</v-icon></v-btn>
                  <!-- Ajouter un sous-titre avec dropdown menu -->
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn title="Ajouter un sous-titre" fab x-small elevation="0" v-bind="attrs" class="ml-6"
                        v-on="on" color="primary">
                        <v-icon>
                          mdi-text-box-plus
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="createSubtitle('Text Here', 0); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                        <v-list-item-title>
                          Ligne 1
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="createSubtitle('Text Here', 1); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                        <v-list-item-title>
                          Ligne 2
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="createSubtitle('Text Here', 2); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                        <v-list-item-title>
                          Ligne 3
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="createSubtitle('Text Here', 3); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                        <v-list-item-title>
                          Ligne 4
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- subtitle bulk edit -->
                  <v-btn fab x-small elevation="0" @click="bulkEdit = !bulkEdit" :color="'primary'" :disabled="!selectedSubtitles.length">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-col>
              <v-col ref="scrollableSub" :style="`width:${subtitleWidth}px;`"
                class="subtitle mx-0 pb-16 pt-16 my-0 px-0">
                <draggable v-model="subtitles" group="people" @start="drag = true" @end="drag = false" handle=".item">
                  <v-col v-for="(subtitle, b) in subtitlesOrdered" :key="`scrollsub${b}`" no-action
                    class="pa-0 px-2 ma-0">
                    <SubtitleEditorComponent @update:subtitle="subtitle = $event"
                      @update:selectedSubtitles="selectedSubtitles = $event"
                      :selectedSubtitles="selectedSubtitles"
                      @deleteSubtitle="deleteSubtitle($event)" @update:speakingRateChange="speakingRateChange($event)"
                      @deleteAudioSource="deleteAudioSource($event)"
                      @update:selectedCharacter="selectedCharacter = $event"
                      @update:selectedSubtitle="selectedSubtitle = $event" :subtitle="subtitle"
                      :characters="getArrayOfAvailableCharacter" :index="b" :selectedSubtitle="selectedSubtitle"
                      :settings="settings" :sources="sources" :isRecording="isRecording"
                      :selectedCharacter="selectedCharacter" />
                  </v-col>
                </draggable>
                <v-col v-if="!subtitlesOrdered.length">
                  <v-card elevation="0">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" class="text-center">
                          <v-btn fab elevation="0" color="primary" v-if="false"
                            @click="createSubtitle('Text Here'); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                            <v-icon x-large color="white">
                              mdi-text-box-plus
                            </v-icon>
                          </v-btn>
                          <!-- Ajouter un sous-titre avec dropdown menu -->
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn title="Ajouter un sous-titre" fab elevation="0" v-bind="attrs" class="ml-6"
                                v-on="on" color="primary">
                                <v-icon x-large>
                                  mdi-text-box-plus
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                @click="createSubtitle('Text Here', 0); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                                <v-list-item-title>
                                  Ligne 1
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="createSubtitle('Text Here', 1); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                                <v-list-item-title>
                                  Ligne 2
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item
                                @click="createSubtitle('Text Here', 2); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                                <v-list-item-title>
                                  Ligne 3
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <h3 class="primary--text">{{ $t('subtitle_editor.no_subtitle') }}</h3>
                          <p>{{ $t('subtitle_editor.no_subtitle_description') }}</p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col class="mb-16"></v-col>
              </v-col>
              <v-col cols="12" style="max-height:20vh;overflow: scroll;" v-if="false">
                <v-list dense>
                  <v-list-group v-for="(character, b) in getArrayOfAvailableCharacter" :key="`availabchara${b}`">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ character }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <v-col cols="12">
                      <v-btn fab color="red" small @click="recordAudio(character)">
                        <v-icon small>
                          <!-- audio record -->
                          mdi-microphone
                        </v-icon>
                      </v-btn>
                      <br />
                      <template v-if="sources.find(map => map.character == character && !map.subtitle)?.src">
                        <v-card class="secondary" elevation="0">
                          <v-card-text>
                            <v-subheader class="mt-0 pt-0">
                              {{ $t('title.audio') }}
                            </v-subheader>
                            <v-btn color="red" icon
                              style="position:absolute; right:2em; top:2em; cursor:pointer;z-index:100000;"
                              @click="deleteAudioSource(null, sources.find(map => map.character == character && !map.subtitle)?.src)">
                              <v-icon style=" cursor:pointer;" small>mdi-delete</v-icon>
                            </v-btn>
                            <!-- audio player -->
                            <wave-surfer :height="50" :interact="false"
                              :source="sources.find(map => map.character == character && !map.subtitle)?.src" />
                            <audio preload="auto" :muted="isRecording" class="mt-12" :id="`audio${character}`"
                              :ref="`audio${character}`"
                              :src="sources.find(map => map.character == character && !map.subtitle)?.src"
                              crossorigin="anonymous" controls></audio>
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-col>
                  </v-list-group>
                </v-list>
              </v-col>
            </v-col>
          </v-col>
        </div>
      </div>
      <div class="rowbottom">
        <div class="column3" lg12 :style="{
      }">
          <v-col class="pa-0 ma-0">
            <v-toolbar v-if="!cinemaMode" dense style="max-width:100vw;overflow-x:scroll;overflow-y: hidden;">
              <!-- add subtitle -->
              <v-btn fab x-small elevation="0" color="primary" v-if="false"
                @click="createSubtitle('Text Here'); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                <v-icon small>mdi-text-box-plus</v-icon>
              </v-btn>
              <!-- Ajouter un sous-titre avec dropdown menu -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn title="Ajouter un sous-titre" fab x-small elevation="0" v-bind="attrs" v-on="on"
                    color="primary">
                    <v-icon>
                      mdi-text-box-plus
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="createSubtitle('Text Here', 0); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                    <v-list-item-title>
                      Ligne 1
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="createSubtitle('Text Here', 1); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                    <v-list-item-title>
                      Ligne 2
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="createSubtitle('Text Here', 2); selectedSubtitle = subtitles[subtitles.length - 1]; scrollTo(subtitles[subtitles.length - 1].id)">
                    <v-list-item-title>
                      Ligne 3
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn fab x-small elevation="0" :color="isRecording ? 'red' : ''"
                @click="isRecording ? stopRecording() : recordAudio()">
                <v-icon :color="isRecording ? 'white' : 'red'">mdi-microphone</v-icon>
              </v-btn>
              <!-- videoSpeedRate -->
              <v-btn fab x-small elevation="0" @click="incrementSpeedRate()">
                x{{ videoSpeedRate }}
              </v-btn>
              <!-- Playback -->
              <v-btn fab x-small elevation="0" v-if="videoPaused" @click="playVideo()">
                <v-icon>mdi-play</v-icon>
              </v-btn>
              <v-btn v-else fab x-small elevation="0" @click="playVideo()">
                <v-icon>mdi-pause</v-icon>
              </v-btn>
              <!-- go to start -->
              <v-btn fab x-small elevation="0" @click="$refs.video.currentTime = 0;">
                <v-icon>mdi-skip-backward</v-icon>
              </v-btn>
              <!-- Forward -->
              <v-btn fab x-small elevation="0" @click="$refs.video.currentTime -= 1 / base_rate;">
                <v-icon>mdi-skip-previous</v-icon>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="$refs.video.currentTime += 1 / base_rate;">
                <v-icon>mdi-skip-next</v-icon>
              </v-btn>
              <!-- go to end -->
              <v-btn fab x-small elevation="0" @click="$refs.video.currentTime = $refs.video.duration;">
                <v-icon>mdi-skip-forward</v-icon>
              </v-btn>
              <!-- /Playback -->
              <!-- editing subtitles -->
              <v-btn v-if="false" fab x-small elevation="0" @click="cutMode = !cutMode"
                :color="cutMode ? 'primary' : ''">
                <v-icon>mdi-content-cut</v-icon>
              </v-btn>
              <!-- editing subtitles -->
              <v-btn class="ml-3" fab x-small elevation="0" @click="addItem('↑')" v-if="false">
                ↑
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addItem('↓')" v-if="false">
                ↓
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addLoop()">
                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 74.263 89">
                  <path id="Tracé_9" data-name="Tracé 9" d="M0,0,58,58" transform="translate(8.132 13)" fill="none"
                    stroke="red" stroke-width="5" />
                  <line id="Ligne_30" data-name="Ligne 30" y1="58" x2="58" transform="translate(8.132 13)" fill="none"
                    stroke="red" stroke-width="5" />
                  <line id="Ligne_31" data-name="Ligne 31" y2="89" transform="translate(37.132)" fill="none"
                    stroke="red" stroke-width="5" />
                </svg>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addEndDialog()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.235" height="20" viewBox="0 0 65.235 109">
                  <rect id="Rectangle_55" data-name="Rectangle 55" width="10" height="104"
                    transform="translate(35.235 5)" fill="#c43636" />
                  <line id="Ligne_19" data-name="Ligne 19" y1="66.494" x2="66.948" transform="translate(1.762 14)"
                    fill="none" stroke="#c43636" stroke-width="10" />
                  <line id="Ligne_73" data-name="Ligne 73" y1="67" x2="66.774" transform="translate(12.735 33.5)"
                    fill="none" stroke="#c43636" stroke-width="10" />
                </svg>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addSvg(1)" v-if="false">
                <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 44.679 37.844">
                  <g id="Composant_12_1" data-name="Composant 12 – 1" transform="translate(1.36 2)">
                    <g id="Groupe_20" data-name="Groupe 20" transform="translate(0 7.685)">
                      <line id="Ligne_36" data-name="Ligne 36" x2="41.966" y2="25.449" transform="translate(0 1)"
                        fill="none" stroke="#fff" stroke-width="4" />
                      <line id="Ligne_37" data-name="Ligne 37" y1="26.449" x2="41.966" transform="translate(0 0)"
                        fill="none" stroke="#fff" stroke-width="4" />
                    </g>
                    <path id="Tracé_11" data-name="Tracé 11"
                      d="M0-8.452s10.438-9.686,20.93-9.686S41.966-8.452,41.966-8.452" transform="translate(0 18.138)"
                      fill="none" stroke="#fff" stroke-width="4" />
                  </g>
                </svg>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addSvg(2)" v-if="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 42.934 10.068">
                  <path id="Tracé_12" data-name="Tracé 12"
                    d="M0-11.769s10.153-6.369,20.357-6.369,20.46,6.369,20.46,6.369" transform="translate(1.063 20.138)"
                    fill="none" stroke="#fff" stroke-width="4" />
                </svg>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addSvg(3)" v-if="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 44.729 13.386">
                  <path id="Tracé_13" data-name="Tracé 13" d="M0,0S10.473,9.932,20.968,9.932,41.979,0,41.979,0"
                    transform="translate(1.376 1.454)" fill="none" stroke="#fff" stroke-width="4" />
                </svg>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addSceneChange()">
                |
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addNote('«TEXT»')">
                «»
              </v-btn>
              <!-- dropdown -->
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small elevation="0" v-bind="attrs" v-on="on">
                    RESPIRATIONS
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="addItem('↗')">
                    <v-list-item-title>
                      ↗
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="addItem('↘')">
                    <v-list-item-title>
                      ↘
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="createSubtitle('(H)')">
                    <v-list-item-title>
                      (H)
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="createSubtitle('(HH)')">
                    <v-list-item-title>
                      (HH)
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="createSubtitle('(mH)')">
                    <v-list-item-title>
                      (mH)
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="createSubtitle('(mHH)')">
                    <v-list-item-title>
                      (mHH)
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small elevation="0" v-bind="attrs" v-on="on">
                    RÉACTIONS
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="react in reactions" :key="react" @click="createSubtitle(react)">
                    <v-list-item-title>
                      {{ react }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn fab x-small elevation="0" @click="addItem('↦')">
                <span :style="{
        fontSize: '2em'
      }">
                  ↦
                </span>
              </v-btn>
              <v-btn fab x-small elevation="0" @click="addItem('↤')">
                <span :style="{
        fontSize: '2em'
      }">
                  ↤
                </span>
              </v-btn>
              <v-btn fab x-small class="mr-3" elevation="0" :color="deleteItems ? 'red' : ''"
                @click="enableDeleteItems()">
                <v-icon x-small>mdi-delete</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn x-small outlined color="error" class="mr-1" v-for="(loop, b) in ndd.loops" :key="`button${loop.x}`"
                @click="$refs.video.currentTime = loop.x / (base_rate * settings.fontSize)">
                {{ b }}
              </v-btn>
              <!-- vertical slider height -->
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small elevation="0" v-bind="attrs" v-on="on">
                    <v-icon small>
                      mdi-arrow-expand-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="settings.height = 100">
                    <v-list-item-title>
                      100px
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="settings.height = 150">
                    <v-list-item-title>
                      150px
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="settings.height = 200">
                    <v-list-item-title>
                      200px
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="settings.height = 250">
                    <v-list-item-title>
                      250px
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="settings.height = 300">
                    <v-list-item-title>
                      300px
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <!-- cursor -->
            <div
            v-if="bottomTab != 2"
              :style="`height:${(settings.height) + 15}px;background-color:#e74970;border-radius:20px;box-shadow: 1px 1px 4px black;width:${5 / zoom}px;position:absolute;left:${innerWidth / 2}px;z-index:200;`">
              <div style="position:absolute;">
                <!-- timer -->
              </div>
            </div>
            <v-tabs-items v-model="bottomTab" touchless>
              <v-tab-item :style="{
        overflow: 'scroll',
        maxHeight: '50vh'
      }" :transition="false" eager :class="bottomTab == 1 ? 'rythmoTabActive' : ''">
                <div style="width:100%; overflow-x: hidden;overflow-y: hidden;"
                  :style="`transform:scaleX(${zoom}); width:${100 / zoom}%; height:${(settings.height + 15)}px; transform-origin:left;white-space: nowrap;`"
                  ref="offsetDiv" id="offsetDiv" >
                  <!-- Subtitles -->
                  <div :style="`width:${innerWidth / 2}px;display:inline-block;`">
                    <div style="height:15px;" class="timeline-cell">
                    </div>
                    <div class="timeline-cell" id="timeline" @click="scrollToTimeline" :style="`left:${(offsetDuration * 100)
        }px;position:relative;width:100%;height:${settings.height}px;`"></div>
                  </div>
                  <div ref="rythmo" :style="{
        width: (videoDuration * (base_rate) * settings.fontSize) + 'px',
        height: `${settings.height}px`,
        display: 'inline-block'
      }" v-touch="{
        start: (e) => swipeMove(e),
        end: (e) => swipeMove(e),
        move: (e) => swipeMove(e),
      }">


<div v-if="videoSource" class="base-audio-wave" :style="`width:${(videoDuration * base_rate * settings.fontSize)}px;position:absolute;bottom:0;opacity:1;height:${settings.height}px;    pointer-events: none;`">
                      <wave-surfer :source="videoSource" :height="settings.height" :interact="false" 
                      @click="scrollToTimeline"
                      :muted="true"
                      ref="baseaudio"
                      :options="{ 'waveColor': '#ffffff' }" :style="{
                        touchAction: 'none',
                        // position: 'relative',
                        // pointerEvent: 'none',
                            backgroundColor: 'rgba(12,12,12,0)',
                            borderRadius: '10px',
                          }" 
                           :waveColor="'rgba(100, 100, 100, .3)'" :progressColor="'rgba(100, 100, 100, .3)'" :cursorColor="'rgba(0,0,0,0)'"
                      />
                    </div>
                    <div style="position:absolute;top:-10px;width:100%;z-index:100;"
                      v-if="$refs.video && $refs.video.duration">
                      <!-- each frame tick -->
                      <template v-if="false">
                        <div
                          :style="`width:${(settings.fontSize)}px; ${i % base_rate == 0 ? 'color:red;' : 'color:grey;'}${i % 2 == 0 ? 'height:15px;' : 'height:20px;'}overflow:hidden;position:absolute;left:${(i * settings.fontSize)}px;`"
                          style="z-index:100000;color:white;"
                          v-for="(time, i) in Math.round($refs.video.duration * base_rate)" :key="`timeline${i}`">
                          |
                        </div>
                      </template>
                    </div>
                    <div style="position:absolute;width:100%;z-index:100;" v-if="$refs.video && $refs.video.duration">
                      <!-- timeline tick bar -->
                      <template v-if="false">
                        <div v-for="(time, i) in Math.round(videoDuration * base_rate)"
                          :style="`width:${(base_rate * settings.fontSize)}px;position:absolute;left:${(i * base_rate * settings.fontSize) - settings.fontSize * 1.5}px;top:-20px;`"
                          style="display:inline-block;z-index:100000;color:white;" :key="`timeline${i}`">
                          <!-- timeline 00:00:00 -->
                          {{ $moment((i * 1000)).format('HH:mm:ss') }}
                        </div>
                      </template>
                    </div>
                    <div id="replace-item" class="replace-item" :data-id="item.id" data-type="items" :data-x="item.x"
                      :data-y="item.y" style="z-index:1000;position:absolute;"
                      :style="`transform:translate(${item.x}px, ${item.y}px);font-size:2em;filter:contrast(0);`"
                      v-for="(item, i) in ndd.items" :key="`ndd${i}`">
                      <v-btn v-if="deleteItems" @click="ndd.items = ndd.items.filter((e) => e.id != item.id)" fab
                        x-small elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      {{ item.text }}
                    </div>
                    <div id="replace-item" class="replace-item" :data-id="item.id" data-type="notes" :data-x="item.x"
                      :data-y="item.y" style="z-index:1000;position:absolute;"
                      :style="`transform:translate(${item.x}px, ${item.y}px);`" v-for="(item, z) in ndd.notes"
                      :key="`note${item.x}-${z}`">
                      <v-btn v-if="deleteItems" @click="ndd.notes = ndd.notes.filter((e) => e.id != item.id)" fab
                        x-small elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-chip>
                        <v-text-field v-if="item.active" :style="{
        width: 'auto'
      }" v-model="item.text" dense @blur="item.active = false" hide-details></v-text-field>
                        <span @click="($event) => {
        item.active = true
        $nextTick(() => {
          $event.target.focus()
        })
      }" v-else>
                          {{ item.text }}
                        </span>
                      </v-chip>
                    </div>
                    <div class="scenechange"
                      :style="`position:absolute;transform:translate(${sceneChange.x}px, 0px);height:${settings.height}px;width:5px;background-color:grey; z-index:1000;`"
                      v-for="sceneChange in ndd.sceneChanges" :key="`scene${sceneChange.x}`">
                      <v-btn style="position:absolute;top:0;" v-if="deleteItems"
                        @click="ndd.sceneChanges = ndd.sceneChanges.filter((e) => e.id != sceneChange.id)" fab x-small
                        elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                    <div class="loop"
                      :style="`position:absolute;transform:translate(${loop.x}px, 0px);height:${settings.height}px;width:2px;background-color:red; z-index:1000;`"
                      v-for="(loop, c) in ndd.loops" :key="`loop${loop.x}`">
                      <v-btn v-if="deleteItems" @click="ndd.loops = ndd.loops.filter((e) => e.id != loop.id)" fab
                        x-small elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <div :style="{
        left: '-36px',
        top: '50%',
        position: 'absolute',
        transform: 'translate(0, -50%)',
      }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="74.263" height="40" viewBox="0 0 74.263 89">
                          <path id="Tracé_9" data-name="Tracé 9" d="M0,0,58,58" transform="translate(8.132 13)"
                            fill="none" stroke="red" stroke-width="5" />
                          <line id="Ligne_30" data-name="Ligne 30" y1="58" x2="58" transform="translate(8.132 13)"
                            fill="none" stroke="red" stroke-width="5" />
                          <line id="Ligne_31" data-name="Ligne 31" y2="89" transform="translate(37.132)" fill="none"
                            stroke="red" stroke-width="5" />
                          <text id="OUT" transform="translate(84.235 26)" fill="#b5423c" font-size="27"
                            font-family="HelveticaNeue, Helvetica Neue">
                            <tspan x="0" y="0">{{ c }}</tspan>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div class="endDialog"
                      :style="`position:absolute;transform:translate(${endDialog.x}px, 0px);height:${settings.height}px;width:2px;background-color:red; z-index:1000;`"
                      v-for="endDialog in ndd.endDialog" :key="`endDialog${endDialog.x}`">
                      <v-btn v-if="deleteItems"
                        @click="ndd.endDialog = ndd.endDialog.filter((e) => e.id != endDialog.id)" fab x-small
                        elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <div :style="{
        left: '-25px',
        top: '50%',
        position: 'absolute',
        transform: 'translate(0, -50%)',
      }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="74.263" height="40"
                          viewBox="0 0 140.235 102.265">
                          <line id="Ligne_19" data-name="Ligne 19" y1="66.494" x2="66.948"
                            transform="translate(1.762 14)" fill="none" stroke="#c43636" stroke-width="5" />
                          <line id="Ligne_73" data-name="Ligne 73" y1="67" x2="66.774"
                            transform="translate(12.735 33.5)" fill="none" stroke="#c43636" stroke-width="5" />
                          <text id="OUT" transform="translate(84.235 26)" fill="#b5423c" font-size="27"
                            font-family="HelveticaNeue, Helvetica Neue">
                            <tspan x="0" y="0">OUT</tspan>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div id="replace-item" class="replace-item" :data-id="item.id" data-type="svg" :data-x="item.x"
                      :data-y="item.y" style="z-index:1000;position:absolute;"
                      :style="`transform:translate(${item.x}px, ${item.y}px);`" v-for="(item, z) in ndd.svg"
                      :key="`note${z}`">
                      <v-btn v-if="deleteItems" @click="ndd.svg = ndd.svg.filter((e) => e.id != item.id)" fab x-small
                        elevation="0" color="error">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <template v-if="item.svg == 1">
                        <svg :style="{
        filter: 'contrast(0)'
      }" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 44.679 37.844">
                          <g id="Composant_12_1" data-name="Composant 12 – 1" transform="translate(1.36 2)">
                            <g id="Groupe_20" data-name="Groupe 20" transform="translate(0 7.685)">
                              <line id="Ligne_36" data-name="Ligne 36" x2="41.966" y2="25.449"
                                transform="translate(0 1)" fill="none" stroke="#fff" stroke-width="4" />
                              <line id="Ligne_37" data-name="Ligne 37" y1="26.449" x2="41.966"
                                transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="4" />
                            </g>
                            <path id="Tracé_11" data-name="Tracé 11"
                              d="M0-8.452s10.438-9.686,20.93-9.686S41.966-8.452,41.966-8.452"
                              transform="translate(0 18.138)" fill="none" stroke="#fff" stroke-width="4" />
                          </g>
                        </svg>
                      </template>
                      <template v-if="item.svg == 2">
                        <svg :style="{
        filter: 'contrast(0)'
      }" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 42.934 10.068">
                          <path id="Tracé_12" data-name="Tracé 12"
                            d="M0-11.769s10.153-6.369,20.357-6.369,20.46,6.369,20.46,6.369"
                            transform="translate(1.063 20.138)" fill="none" stroke="#fff" stroke-width="4" />
                        </svg>
                      </template>
                      <template v-if="item.svg == 3">
                        <svg :style="{
        filter: 'contrast(0)'
      }" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 44.729 13.386">
                          <path id="Tracé_13" data-name="Tracé 13" d="M0,0S10.473,9.932,20.968,9.932,41.979,0,41.979,0"
                            transform="translate(1.376 1.454)" fill="none" stroke="#fff" stroke-width="4" />
                        </svg>
                      </template>
                    </div>
                    
                
                    <div
                      :style="`width:${(videoDuration * base_rate * settings.fontSize)}px;position:relative;height:${settings.height}px;`">
                      <div class="timeline-cell" id="timeline" @click="scrollToTimeline"
                        :style="`left:${(offsetDuration * 100)
        }px;
                          position:relative;width:100%;background-image:url(${settings.backgroundImage});background-size:contain;background-repeat-x:repeat;background-repeat: repeat-x;height:${settings.height}px;z-index:100;`">
                        <div style="position:absolute;width:100%;height:50px;" class="rythmo-ruban"></div>
                        <template v-for="(subtitle, b) in subtitles">
                          <!-- Composant De Dialogue -->
                          <DialogueComponent @click="selectedSubtitle = subtitle; subtitle.active = true;"
                            @changeToLine="changeToLine"
                            :cursorTime="cursorTime" :selectedSubtitle="selectedSubtitle" :settings="settings"
                            :characterColor="settings.charactersColors[subtitle.character]" :zoom="zoom"
                            :singleLine="singleLine" :cutMode="cutMode"
                            :spaces="fontFamily.find(map => map.value == settings.fontFamily).spaces"
                            :saveInProgress="saveInProgress" @cutSubtitle="cutSubtitle" :offsetDuration="offsetDuration"
                            :character="subtitle.character" :darkMode="darkMode" :fontFamily="fontFamily"
                            @updateSubtitle="updateSubtitle" :subtitle="subtitle" :id="subtitle.id"
                            :videoDuration="videoDuration" :key="`dialg${b}`" />
                        </template>
                      </div>
                    </div>
                  </div>
                  <div :style="`width:${innerWidth / 2}px;display:inline-block;`">
                    <div class="timeline-cell" id="timeline" @click="scrollToTimeline"
                      :style="`left:${(offsetDuration * 100)}px;position:relative;width:100%;background-color:${choseColorBySeedString()};height:${settings.height}px;`">
                    </div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item :transition="false" eager>
                <!-- Wave Audio -->
                <template>
                  <div style="position:absolute;z-index:1000;top:15px;">
                    <div v-for="(sourcesMerged, i) in sourcesGroupedByCharacters" :key="`stickyaudio${i}`"
                      :style="`border-bottom:1px solid black;height:${settings.height / getArrayOfAvailableCharacter.length}px`"
                      style="top:0;left:0;z-index:1000;width:100%;">
                      <div class="px-2 py-1" @click="selectCharacterAudio(i)"
                        style="left:0;width:150px;overflow:hidden;height:100%;background-color:#2c2c2c;box-shadow: inset 0px 0px 4px black;z-index:1000;cursor:pointer;">
                        <span :class="selectedCharacter == i ? 'primary--text' : ''">{{ i }}</span>
                        <template v-if="true">
                          <br />
                          <v-btn color="red" outlined x-small @click="isRecording ? stopRecording() : recordAudio(i)">
                            <!-- mute -->
                            REC
                          </v-btn>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
                <div @click="scrollToTimeline" style="width:100%; overflow:hidden;"
                  :style="`transform:scaleX(${zoom}); width:${100 / zoom}%; height:${settings.height}px;transform-origin:left;white-space: nowrap;padding-top:15px;`"
                  ref="offsetDivAudio" id="offsetDivAudio">
                  <div :style="`width:${innerWidth / 2}px;display:inline-block;`">
                    <div class="timeline-cell" id="timeline" @click="scrollToTimeline"
                      :style="`left:${(offsetDuration * 100)
        }px;position:relative;width:100%;background-color:${choseColorBySeedString(a)};height:${settings.height / getArrayOfAvailableCharacter.length}px;`"
                      v-for="(character, a) in subtitlesGroupedByCharacters" :key="`groupbychara${a}`"></div>
                  </div>
                  <div
                    :style="`width:${(videoDuration * (base_rate) * settings.fontSize)}px;position:relative;display:inline-block;top:0px;`"
                    class="rythmo-ruban"
                    >
                    <!-- audio record bar -->
                    <div v-if="isRecording" :style="{
        width: (videoTime * base_rate * settings.fontSize) - (startRecording * base_rate * settings.fontSize) + 'px',
        left: startRecording * base_rate * settings.fontSize + 'px',
        position: 'absolute',
        top: getLevelHeightOfSelectedCharacter(selectedCharacter) + 'px',
        height: `${settings.height / getArrayOfAvailableCharacter.length}px`,
        backgroundColor: '#FCB6CD',
        opacity: 0.8,
        zIndex: 1000,
      }"
      >
                    </div>

                      

                    <div id="timeline"
                      :style="`position:relative;width:100%;
      width:${(videoDuration * (base_rate) * settings.fontSize)}px;
                                                                                                                                                                                                                    left:${(offsetDuration * 100)
        }px;
                                                                                                                                                                                                                                                                                                                                                        height:${settings.height / getArrayOfAvailableCharacter.length}px;background-color:${i == 'all' ? 'red' : choseColorBySeedString(i)}}`"
                      v-for="(sourcesMerged, i) in sourcesGroupedByCharacters" :key="`sourcebychara${i}`">
                      <div :data-id="source.id" :data-x="source.start * (base_rate) * settings.fontSize
        " :class="i != 'all' ? '' : ''" :data-source="source.name" v-for="(source, b) in sourcesMerged"
                        :key="`audidrag${b}`" :style="`${calcMarginLeftAndWidth(source.start, source.end)};white-space; position:absolute; nowrap;opacity:1; cursor:pointer;`
        "> <v-btn @click="deleteAudioSource(source.id);"
                          style="position:absolute;right:0;top:0;z-index:1000;" small icon>
                          <v-icon color="white" small>mdi-delete</v-icon></v-btn>
                        <wave-surfer :options="{ 'waveColor': '#ffffff' }" :style="{
                            position: 'relative',
                            zIndex: `${100 + source.id}`,
                            backgroundColor: '#6449D1',
                            borderRadius: '10px',
                          }" v-show="bottomTab == 1" :height="(settings.height / getArrayOfAvailableCharacter.length)"
                          :interact="false" :waveColor="'#ffffff'" :progressColor="'#ffffff'" :cursorColor="'#ffffff'"
                          :cursorWidth="0" :muted="source.muted ? source.muted : false" :source="source.src"
                          class="waveform" :ref="`audio${source.character}${source.id}`" />
                      </div>
                    </div>
                  </div>
                  <div :style="`width:${innerWidth / 2}px;display:inline-block;`">
                    <div class="timeline-cell" id="timeline" @click="scrollToTimeline"
                      :style="`left:${(offsetDuration * 100)
        }px;position:relative;width:100%;background-color:${choseColorBySeedString(a)};height:${settings.height / getArrayOfAvailableCharacter.length}px;`"
                      v-for="(character, a) in subtitlesGroupedByCharacters" :key="`groupbychara${a}`"></div>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <v-row class="mx-3" v-if="$refs.video">
                      <v-col v-if="$refs.video.duration > (10 * 60)" cols=12>
                        <v-alert type="warning" dismissible>
                          {{ $t('error.export_warning') }}
                        </v-alert>
                      </v-col>
                      <v-col cols="12">
                        <!-- Choose export mode between Youtube and Tiktok with radio  -->
                        Format
                        <v-radio-group v-model="exportMode" row>
                          <v-radio label="Youtube (Paysage) - 1920x1080" value="youtube"></v-radio>
                          <v-radio label="Tiktok (Portrait) - 1080x1920" value="tiktok"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols=6>
                        Filtre AI
                        <v-checkbox v-model="settings.iafilter" :label="$t('options.denoise_audio')"></v-checkbox>
                      </v-col>
                      <v-col cols=6>
                        {{ $t('options.without_rythmo') }}
                        <v-checkbox v-model="settings.without_rythmo"
                          :label="$t('options.without_rythmo')"></v-checkbox>
                      </v-col>
                      <v-col cols=12>
                        <!-- preroll -->
                        Pre-roll<br />
                        <v-btn v-for="i in [0, 1, 2, 3]" :key="i" :color="settings.preroll == i ? 'primary' : null"
                          @click="settings.preroll = i">
                          {{ i }}s
                        </v-btn>
                      </v-col>
                      <v-col cols=12>
                        Frame Rate
                        <v-select :items="framerates" v-model="settings.framerate" label="Framerate" value="value"
                          hide-details @change="checkFrameratePlan()">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="createExportTask(); popupExport = false;">
                      Exporter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </div>
      </div>
      <!-- ToolBar -->
      <div cols="12" style="position:absolute;z-index:100;width:100%;" class="py-0 mb-0"
        v-if="!rendering && selectedSubtitle.id && cinemaMode && !isRecording">
        <v-card>
          <v-card-text>
            <v-row class="my-0">
              <v-col lg="2" cols="10" class="my-0">
                <!-- <v-text-field hide-details v-model="selectedSubtitle.character" outlined dense /> -->
                <v-text-field label="character" dense outlined hide-details v-model="selectedSubtitle.character">
                  <template v-slot:append>
                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon color="primary" v-on="on">
                          <v-icon small>
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-for="i in getArrayOfAvailableCharacter" @click="selectedCharacter = i"
                          :key="`charaarray${i}`">
                          {{ i }}
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" class="my-0">
                <v-text-field hide-details v-model="selectedSubtitle.text" outlined dense />
              </v-col>
            </v-row>
            <v-btn small @click="deleteSubtitle(selectedSubtitle.id); selectedSubtitle = { id: null }" color="red" icon
              style="position:absolute; right:2em; top:0; cursor:pointer;">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
            <!-- close button -->
            <v-btn small @click="selectedSubtitle = { id: null }" color="primary" icon
              style="position:absolute; right:0em; top:0; cursor:pointer;">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- Render Window -->
    <v-dialog v-model="renderDone">
      <v-card>
        <v-card-text>
          <v-col>
            <v-row>
              <v-col cols="12" lg="6">
                <video crossorigin="anonymous" ref="outputVideo" v-if="outputURL" width="100%" :src="outputURL"
                  controls></video>

                <!-- Image list player -->
                <canvas id="canvas"></canvas>
              </v-col>
              <v-col cols="12" lg="6" class="py-6">
                <v-row>
                  <v-col cols="12">
                    <h1>{{ $t('title.render_done') }}</h1>
                    <h3 class="my-6">
                      {{ $t('title.render_done_subtitle') }}
                    </h3>
                    <!-- Video characteristique -->
                    <v-simple-table>
                      <tbody v-if="$refs.outputVideo">
                        <tr>
                          <td>{{ $t('title.render_done_duration') }}</td>
                          <td>{{ $refs.outputVideo.duration }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('title.render_done_size') }}</td>
                          <td>{{
        $refs.outputVideo.videoWidth + 'x' + $refs.outputVideo.videoHeight
      }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-btn block class="mr-6" @click="downloadRenderVideo()" color="primary" dark>{{
        $t('button.download') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Overlay Rendering -->
    <v-overlay :value="rendering" class="text-center">
      <h2>
        {{ renderStatusMessage }}
      </h2>
      <template v-if="renderProgress">
        {{ renderProgress }}%
      </template>
      <template v-else>
        {{ $t('title.export_in_prepare') }}
      </template>
      <h3>
        Don't close or resize this window
      </h3>
      <v-progress-linear indeterminate></v-progress-linear>
    </v-overlay>
    <!-- Overlay timer -->
    <v-overlay :value="timer > 0">
      <h1 class="display-1">
        {{ timer }}
      </h1>
    </v-overlay>
    <!-- Popup plan -->
    <v-dialog v-model="openPopupPlan">
      <v-card>
        <v-card-text>
          <plan-view :reason="reasonPlan" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Settings -->
    <v-dialog v-model="openSettings">
      <v-card>
        <v-card-title>
          <v-toolbar dense flat>
            <v-toolbar-title>{{ $t('title.settings') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="openSettings = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text style="overflow:scroll; max-height: 80vh;">
          <v-row>
            <v-col cols="4">
              <v-card>
                <v-card-title>
                  <span class="headline">Settings</span>
                </v-card-title>
                <v-card-text>
                  <!-- font settings -->
                  <v-row>
                    <v-col cols="12" class="my-0 py-0">
                      <v-select :items="fontFamily" label="Font" outlined dense
                        v-model="settings.fontFamily"></v-select>
                    </v-col>
                    <!-- language -->
                    <v-col cols="12" class="my-0 py-0">
                      <v-select :items="getAvailableLanguage" label="Language" outlined dense
                        v-model="settings.language"></v-select>
                    </v-col>
                    <!-- design style checkbox filter -->
                    <v-col cols="12" class="my-0 py-0">
                      <v-row>
                        <v-col cols="6">
                          <v-checkbox v-for="(theme, c) in Object.keys(backgrounds).splice(0, 5)" :key="c"
                            :label="theme" :value="theme" v-model="choiceTheme[c]" />
                        </v-col>
                        <v-col cols="6">
                          <v-checkbox v-for="(theme, c) in Object.keys(backgrounds).splice(5, 5)" :key="c"
                            :value="theme" v-model="choiceTheme[c + 5]" :label="theme" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-if="false">
                      <v-divider class="my-6"></v-divider>
                      <h3>
                        {{ $t('title.shortcuts') }}
                      </h3>
                      <!-- Shortcuts -->
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td>
                              <kbd>Ctrl</kbd> + <kbd>+</kbd>
                            </td>
                            <td>
                              {{ $t('shortcuts.add_text') }}
                            </td>
                          </tr>
                          <!-- Left arrow -->
                          <tr>
                            <td>
                              <kbd>←</kbd>
                            </td>
                            <td>
                              {{ $t('shortcuts.move_left') }}
                            </td>
                          </tr>
                          <!-- Right arrow -->
                          <tr>
                            <td>
                              <kbd>→</kbd>
                            </td>
                            <td>
                              {{ $t('shortcuts.move_right') }}
                            </td>
                          </tr>
                          <!-- space -->
                          <tr>
                            <td>
                              <kbd>Space</kbd>
                            </td>
                            <td>
                              {{ $t('shortcuts.play_pause') }}
                            </td>
                          </tr>
                          <!-- start record -->
                          <tr>
                            <td>
                              <kbd>CTRL</kbd> + <kbd>K</kbd>
                            </td>
                            <td>
                              {{ $t('shortcuts.record_audio') }}
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <!-- characters list with color picker -->
                    <v-expansion-panels elevation=0>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span>
                            {{ $t('title.characters') }}
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="12" class="my-0 py-0">
                              <v-select :items="getArrayOfAvailableCharacter" label="Character" outlined dense
                                v-model="selectedCharacter"></v-select>
                            </v-col>
                            <v-col cols="12" class="my-0 py-0">
                              <v-color-picker @update:color="updateColor(selectedCharacter, $event)" dot-size="29"
                                mode="hexa"></v-color-picker>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="8" :style="{
        maxHeight: 'calc(80vh)',
        overflowY: 'scroll'
      }">
              <v-row>
                <v-col cols="12" v-for="(background, i) in  Object.entries(backgroundsFiltered)" :key="i">
                  <v-row cols="12" md="6">
                    <v-col cols="12" md="6" v-for="nb in background[1]" :key="nb">
                      <v-card
                        @click="settings.backgroundImage = `img/templates/br-${background[0]}-${nb}-min.png`; openSettings = false"
                        height="100%" class="pa-0 ma-0">
                        <v-card-text class="pa-0 ma-0">
                          <div :style="{
        height: settings.height + 'px',
        width: '100%',
        backgroundImage: `url(img/templates/br-${background[0]}-${nb}-min.png)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat-x',
        display: 'flex',
        borderRadius: '5px'
      }">
                            <div :style="{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '50%',
        color: settings.charactersColors[selectedCharacter],
        fontSize: '40px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontFamily: settings.fontFamily,
      }">
                              Quand je serais roi je ferais de toi ma reine
                            </div>
                            <!-- exemple of SVG -->
                            <div :style="{
        position: 'absolute',
        margin: '10px',
        right: '0',
        top: '60%',
        width: '40px',
        cursor: 'pointer'
      }">
                              <svg xmlns="http://www.w3.org/2000/svg" height="20" :style="{
        filter: 'contrast(0)',
      }" viewBox="0 0 44.679 37.844">
                                <g id="Composant_12_1" data-name="Composant 12 – 1" transform="translate(1.36 2)">
                                  <g id="Groupe_20" data-name="Groupe 20" transform="translate(0 7.685)">
                                    <line id="Ligne_36" data-name="Ligne 36" x2="41.966" y2="25.449"
                                      transform="translate(0 1)" fill="none" stroke="#fff" stroke-width="4" />
                                    <line id="Ligne_37" data-name="Ligne 37" y1="26.449" x2="41.966"
                                      transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="4" />
                                  </g>
                                  <path id="Tracé_11" data-name="Tracé 11"
                                    d="M0-8.452s10.438-9.686,20.93-9.686S41.966-8.452,41.966-8.452"
                                    transform="translate(0 18.138)" fill="none" stroke="#fff" stroke-width="4" />
                                </g>
                              </svg>
                            </div>
                            <div :style="{
        position: 'absolute',
        margin: '10px',
        right: '4em',
        top: '70%',
        cursor: 'pointer'
      }">
                              <div :style="{
        backgroundColor: '#FDBED3',
        whiteSpace: 'nowrap',
        padding: '5px',
        borderRadius: '5px',
        color: 'black',
      }">
                                Character
                              </div>
                            </div>
                            <!-- cursor exemple -->
                            <div :style="{
        position: 'absolute',
        margin: '10px',
        left: '20%',
        top: '7px',
        height: '90%',
        width: '4px',
        backgroundColor: 'rgb(231, 73, 112)',
      }">
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Loading -->
    <v-overlay :value="loading">
      <h2>
        {{ $t('title.loading') }}
      </h2>
      <v-progress-linear indeterminate></v-progress-linear>
    </v-overlay>
    <!-- Tasks -->
    <v-dialog v-model="taskPopup" max-width="80vw" height="80vh">
      <v-card height="80vh">
        <v-card-title>
          <v-toolbar dense flat>
            <v-toolbar-title>{{ $t('title.tasks') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon :title="$t('button.minimize')" @click="taskPopup = false">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-col cols=12 class="mx-3">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('title.date') }}
                  </th>
                  <th class="text-left">
                    {{ $t('title.task') }}
                  </th>
                  <th class="text-left">
                    {{ $t('title.status') }}
                  </th>
                  <th class="text-left">
                    {{ $t('title.progress') }}
                  </th>
                  <th class="text-right">
                    {{ $t('title.action') }}
                  </th>
                  <th class="text-right">
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(task, b) in tasks" :key="`task${b}`">
                  <td>
                    {{ $moment(task.attributes.createdAt).format('ddd DD/MM/YYYY HH:mm') }}
                  </td>
                  <td>
                    {{ $t(`render.render`) }}
                  </td>
                  <td>
                    {{ $t(`render.${task.attributes.state}`) }}
                  </td>
                  <td>
                    <v-progress-linear height="25" v-if="task.attributes.state != 'done'"
                      :value="task.attributes.progress"
                      :indeterminate="!task.attributes.progress && task.attributes.state != 'failed'">
                      <template v-slot:default="{ value }">
                        <template v-if="Math.ceil(value)">
                          <strong>{{ Math.ceil(value > 100 ? 100 : value) }}%</strong>
                        </template>
                        <template v-else>
                          <strong v-if="task.attributes.state != 'failed'">{{ $t('title.in_queu') }}</strong>
                          <strong v-if="task.attributes.state == 'failed'">{{ $t('render.failed') }}</strong>
                        </template>
                      </template>
                    </v-progress-linear>
                  </td>
                  <td class="text-right">
                    <v-btn elevation="0" color="primary" v-if="task.attributes.state != 'failed'"
                      :disabled="task.attributes.state != 'done'"
                      @click="loadRenderVideo(task.attributes.video.data.attributes.url)">
                      {{ $t('title.download') }}
                    </v-btn>
                  </td>
                  <td>
                    <v-btn elevation="0" color="error" @click="deleteTask(task.id)">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- confirmLeave dialog -->
    <v-dialog v-model="confirmLeavePopup" persistent max-width="50vw">
      <v-card>
        <v-card-title class="headline">
          {{ $t('title.confirm_leave') }}
        </v-card-title>
        <v-card-text>
          {{ $t('text.confirm_leave') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmLeavePopup = false">
            {{ $t('title.stay') }}
          </v-btn>
          <v-btn color="blue darken-1" text
            @click="confirmLeave = true; confirmLeavePopup = false; $router.push(nextRoute)">
            {{ $t('title.leave') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Help Window -->
    <v-dialog v-model="openHelpWindow" :max-height="innerHeight > 600 ? '80vh' : '100vh'">
      <v-card>
        <help-section />
      </v-card>
    </v-dialog>
    <!-- export Window -->
    <v-dialog v-model="popupExport" :max-width="innerWidth > 600 ? '50vw' : '100vw'">
      <v-card>
        <v-card-text>
          <v-row class="mx-3" v-if="$refs.video">
            <v-col v-if="$refs.video.duration > (10 * 60)" cols=12>
              <v-alert type="warning" dismissible>
                {{ $t('error.export_warning') }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <!-- Choose export mode between Youtube and Tiktok with radio  -->
              Format
              <v-radio-group v-model="exportMode" row>
                <v-radio label="Youtube (Paysage) - 1920x1080" value="youtube"></v-radio>
                <v-radio label="Tiktok (Portrait) - 1080x1920" value="tiktok"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols=6>
              Filtre AI
              <v-checkbox v-model="settings.iafilter" :label="$t('options.denoise_audio')"></v-checkbox>
            </v-col>
            <v-col cols=6>
              {{ $t('options.without_rythmo') }}
              <v-checkbox v-model="settings.without_rythmo" :label="$t('options.without_rythmo')"></v-checkbox>
            </v-col>
            <v-col cols=12>
              <!-- preroll -->
              Pre-roll<br />
              <v-btn v-for="i in [0, 1, 2, 3]" :key="i" :color="settings.preroll == i ? 'primary' : null"
                @click="settings.preroll = i">
                {{ i }}s
              </v-btn>
            </v-col>
            <v-col cols=12>
              Frame Rate
              <v-select :items="framerates" v-model="settings.framerate" label="Framerate" value="value" hide-details
                @change="checkFrameratePlan()">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="createExportTask(); popupExport = false;">
            Exporter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Save in progress -->
    <v-overlay v-if="false" :value="saveInProgress" class="text-center">
      <h2>
        {{ $t('title.saving') }}
      </h2>
      <!-- scroled tips -->
      <transition-group name="fade">
        <div v-for="(tip, b) in tips" :key="tip">
          <div v-if="tip.show" :key="`tip${b}`" class="tip">
            <v-alert dense color="primary" class="white--text" type="info">
              {{ tip.text }}
            </v-alert>
          </div>
        </div>
      </transition-group>
      <v-progress-linear :value="uploadProgress"></v-progress-linear>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="5000" color="error" top>
      {{ snackbarMessage }}
    </v-snackbar>

    <v-dialog v-model="inviteFriendModal">
      <InviteFriend  
        :id_project="workspace"
        :settings="settings"
      />
    </v-dialog>
    <!-- bulk edit subtitles dialog with offset start/end -->
    <v-dialog v-model="bulkEdit" max-width="500px">
      <v-card>
        <v-card-title>
          <v-toolbar dense flat>
            <v-toolbar-title>{{ $t('title.bulk_edit') }}</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="bulkEdit = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-row>
            <!-- change character (select character) -->
            <v-col cols="12">
              <v-select :items="getArrayOfAvailableCharacter" label="Character" outlined dense
                v-model="selectedCharacter"></v-select>
            </v-col>
            <v-col cols=12>
              <!-- change lines -->
               <v-select :items="[0, 1, 2, 3]" label="Lines" outlined dense
                  v-model="selectedLineChange"></v-select>
               
            </v-col>
            <v-col cols="12">
              <v-text-field label="Offset Start (in s)" v-model="offsetStart" outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="bulkEditSubtitles(offsetStart); bulkEdit = false;">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
String.prototype.toHtmlEntities = function () {
  return this.replace(/./gm, function (s) {
    // return "&#" + s.charCodeAt(0) + ";";
    return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
  });
};
import interact from 'interactjs';
import WaveSurfer from "wavesurfer.vue";
// import introjs from 'intro.js/intro.js';
import html2canvas from 'html2canvas';
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import * as toWav from 'audiobuffer-to-wav'
import { mapState } from 'vuex';
import { Capacitor } from '@capacitor/core';
import draggable from 'vuedraggable'
// MediaInfo
export default {
  name: 'HomeView',
  data: () => ({
    selectedLineChange: null,
    selectedSubtitles: [],
    offsetStart: 0,
    bulkEdit:false,
    reasonPlan: false,
    inviteFriendModal: false,
    videoLoading: true,
    reactions: [
      "(X)",
      "(mts)",
      "(tsc)",
      "(ah)",
      "(oh)",
      "(ih)",
      "(mhm)",
      "(hm)",
      "(ptt)",
      "(pff)",
      "(unh)",
      "(hun)",
      "(psst)"
    ],
    openSettings: false,
    darkMode: true,
    taskPopup: false,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    singleLine: false,
    maxSize: 20000,
    chunkSize: 20000,
    exportMode: 'youtube',
    choiceTheme: {
      "0": "classic",
      "2": "dark",
      "8": "voxdub",
    },
    openHelpWindow: false,
    onEditingDialog: false,
    offsetDuration: 0,
    tips: [
      {
        text: "Vous pouvez utiliser les flèches gauche et droite pour naviguer dans la timeline",
        show: true
      },
      {
        text: "Vous pouvez utiliser la barre d'espace pour mettre en pause ou reprendre la lecture",
        show: false
      },
      {
        text: "Vous pouvez utiliser la touche CTRL + K pour enregistrer votre voix",
        show: false
      }
    ],
    popupExport: false,
    snackbar:false,
    snackbarMessage: '',
    settings: {
      preroll: 0,
      fontSize: 20,
      backgroundImage: '/img/templates/br-dark-2.png',
      fontFamily: 'Arial',
      fontSpaces: 3.5,
      fontColor: '#ffffff',
      rythmoHeight: 50,
      height: 250,
      framerate: 60,
      iafilter: false,
      language: 'fr',
      charactersColors: {},
      backCharactersColors: {},
      without_rythmo: false,
    },
    framerates: [
      { text: '60', value: 60 },
      { text: '120', value: 120 }
    ],
    fontFamily: [
      { text: 'League', value: 'League', spaces: 1.5 },
      { text: 'Arial', value: 'Arial', spaces: 3.5 },
      { text: 'Times New Roman', value: 'Times New Roman', spaces: 4 },
      { text: 'Courier New', value: 'Courier New', spaces: 1.5 },
      { text: 'Comic Sans MS', value: 'Comic Sans MS', spaces: 3.5 },
      { text: 'Impact', value: 'Impact', spaces: 5 },
      { text: 'Sacramento', value: 'Sacramento', spaces: 4 },
      { text: 'Oooh Baby', value: 'Oooh Baby', spaces: 3 },
      {
        text: "The Girl Next Door", value: "The Girl Next Door", spaces: 3
      }
    ],
    fontColors: [
      { text: '#ffffff', color: '#ffffff' },
      { text: '#000000', color: '#000000' },
      { text: '#3B3B3B', color: '#3B3B3B' },
      { text: '#FFF2D0', color: '#FFF2D0' },
      { text: '#FCB6CD', color: '#FCB6CD' },
      { text: '#DBBDF2', color: '#DBBDF2' },
      { text: '#BDF2CF', color: '#BDF2CF' },
      { text: '#B6F1FC', color: '#B6F1FC' },
      { text: '#FFE0D0', color: '#FFE0D0' },
      { text: '#6449D1', color: '#6449D1' }
    ],
    backgrounds: {
      "classic": 10,
      "bleu": 4,
      "dark": 10,
      "orange": 4,
      "jaune": 3,
      "vert": 4,
      "violet": 4,
      "voxdub": 10,
      "rose": 4
    },
    layoutoffset: 0,
    filigrane: true,
    renderFrame: 0,
    selectedCharacter: null,
    tiktokMode: false,
    argumentResolutionError: false,
    publicWorkspace: false,
    startRecording: 0,
    cutMode: false,
    selectedText: "Hello World",
    timer: 0,
    videoSource: null,
    isRecording: false,
    includeRythmo: true,
    videoTime: 0,
    base_rate: 25,
    currentProgress: 0,
    subtitles: [],
    deleteItems: false,
    cursorTime: 0,
    videoDuration: 0,
    videoSpeedRate: 1,
    selectedSubtitle: { id: null },
    selectedSubtitleType: null,
    videoImagesForRenderer: [],
    renderDone: false,
    ndd: {
      sceneChanges: [],
      notes: [],
      endDialog: [],
      loops: [
      ],
      items: [
      ],
      svg: []
    },
    encoder: null,
    speechRecognition: false,
    uploadProgress: 0,
    timeRef: null,
    confirmLeave: false,
    tasksInterval: null,
    confirmLeavePopup: false,
    outputURL: null,
    cinemaMode: false,
    stripeBandeCounter: 0,
    title: 'New Project',
    isDragging: false,
    baseDraggingX: null,
    bottomTab: 0,
    newTime: false,
    wavesurfer: null,
    renderProgress: 0,
    recordStart: 0,
    recordedChunks: [],
    rendering: false,
    error: false,
    isEditingSubtitle: false,
    errorMessage: null,
    renderStatusMessage: null,
    deleteBaseAudio: false,
    sources: [],
    denoiseAudio: false,
    saveInProgress: false,
    openPopupPlan: false,
    lastSave: false,
    loading: false,
    workspace: null,
    videoPaused: true,
    workspaceData: null,
    zoom: 1,
    operations: []
  }),
  computed: {
    isTaskAlreadyExecuted() {
      return this.tasks.find(task => task.attributes.state == 'waiting' || task.attributes.state == 'queued')
    },
    backgroundsFiltered() {
      let backgrounds = this.backgrounds
      let choiceTheme = this.choiceTheme
      let backgroundsFiltered = {}
      Object.entries(choiceTheme).map(map => {
        if (map[1]) {
          backgroundsFiltered[map[1]] = backgrounds[map[1]]
        }
      })
      return backgroundsFiltered
    },
    ...mapState(['user', 'region', 'tasks']),
    isSafariOrIos() {
      let CapacitorPlatform = Capacitor.getPlatform()
      let Navigator = window.navigator
      let isSafari = /^((?!chrome|android).)*safari/i.test(Navigator.userAgent)
      let isIos = /iPad|iPhone|iPod/.test(Navigator.userAgent) && !window.MSStream
      return isSafari || isIos || CapacitorPlatform == 'ios' || CapacitorPlatform == 'electron'
    },
    rythmoWidth() {
      return this.videoDuration * (25) * this.settings.fontSize
    },
    isoLang() {
      switch (this.settings.language) {
        case 'fr':
          return 'fr-FR'
        case 'en':
          return 'en-US'
        case 'es':
          return 'es-ES'
        case 'de':
          return 'de-DE'
        case 'it':
          return 'it-IT'
        case 'pt':
          return 'pt-PT'
        case 'ru':
          return 'ru-RU'
        case 'ja':
          return 'ja-JP'
        default:
          return 'fr-FR'
      }
    },
    getAvailableLanguage() {
      let array = ['fr']
      if (!this.workspaceData?.attributes?.localizations?.data) return array
      this.workspaceData?.attributes?.localizations?.data.map(localization => {
        array.push(localization.attributes.locale)
      })
      return array
    },
    subtitleHeight() {
      let value = null
      let length = this.getArrayOfAvailableCharacter.length
      if (this.singleLine) {
        return this.innerHeight - 340
      }
      // if (length > 5) length = 5
      value = (this.innerHeight - (340 + (length * 50)))
      return value
    },
    subtitleWidth() {
      return this.innerWidth - 200;
    },
    getArrayOfAvailableCharacter() {
      let characters = this.subtitles.map(subtitle => subtitle.character);
      return [...new Set(characters)];
    },
    displayCurrentSubtitle() {
      if (!this.videoTime) {
        return null;
      }
      return this.subtitles.filter(subtitle => subtitle.start <= this.videoTime && subtitle.end >= this.videoTime);
    },
    sourcesGroupedByCharacters() {
      let sources = {}
      Object.entries(this.subtitlesGroupedByCharacters).map(map => {
        if (!sources[map[0]]) {
          sources[map[0]] = []
        }
      });
      this.sources.map(map => {
        if (!this.getArrayOfAvailableCharacter.find(map => map != map.character)) return
        if (!sources[map.character]) {
          sources[map.character] = []
        }
        map.muted = false
        sources[map.character].push(map)
      })
      // delete sources['all'];
      return sources
    },
    subtitlesGroupedByCharacters() {
      if (this.singleLine) {
        return { "all": this.subtitlesOrdered }
      } else {
        return this.subtitlesOrdered.reduce((acc, subtitle) => {
          if (!acc[subtitle.character]) {
            acc[subtitle.character] = [];
          }
          acc[subtitle.character].push(subtitle);
          return acc;
        }, {});
      }
    },
    subtitlesOrdered() {
      let subtitles = this.subtitles
      // subtitles.sort((a, b) => {
      //   return a.start - b.start
      // })
      return subtitles
    },
  },
  components: {
    InviteFriend: () => import('@/components/InviteFriend.vue'),
    WaveSurfer,
    HelpSection: () => import('@/components/ResourceSearch.vue'),
    PlanView: () => import('../views/PlanView.vue'),
    DialogueComponent: () => import('@/components/Rythmo/DialogueComponent.vue'),
    draggable,
    SubtitleEditorComponent: () => import('@/components/Rythmo/SubtitleEditorComponent.vue'),
    MenuBar: () => import('@/components/MenuBar.vue'),
  },
  methods: {
    bulkEditSubtitles(offsetStart) {
      // selected subtitles is just id's
      let selectedSubtitles = this.selectedSubtitles
      selectedSubtitles.forEach(subtitle => {
        let subtitleToEdit = this.subtitles.find(sub => sub.id == subtitle)
        let newStart = subtitleToEdit.start + parseInt(offsetStart)
        let newEnd = subtitleToEdit.end + parseInt(offsetStart)
        let character = this.selectedCharacter
        subtitleToEdit.start = newStart
        subtitleToEdit.end = newEnd
        if(character){
          subtitleToEdit.character = character
        }
        if(this.selectedLineChange != null){
          subtitleToEdit.y = this.selectedLineChange * (this.settings.height / 4) || 0
        }
      })
      this.selectedSubtitles = []
      this.selectedLineChange = null
      this.offsetStart = 0
    },
    checkIfSubtitleIsEmpty(){
      let emptySubtitle = this.subtitles.find(subtitle => subtitle.text == '')
      if (emptySubtitle) {
        this.snackbar = true
        this.snackbarMessage = 'Un sous-titre est vide'
        return true
      }
      return false
    },
    importSubtitles() {
      this.$refs.inputSousTitre.click()
    },
    updateSubtitle(subtitle, innerText) {
      subtitle.text = innerText
    },
    async detectSceneChange() {
      this.loading = true
      try {
        await this.saveProject()
        await axios.get(`${process.env.VUE_APP_TASK_URI}/detect-cut/${this.workspace}?region=${this.region}`)
        this.loadWorkspace()
      } catch (err) {
        this.error = true
        this.errorMessage = err.message
        console.error(err)
      }
      this.loading = false
    },
    updateColor(character, color) {
      console.log('update color', character, color)
      try {
        if (!character) return
        this.settings.charactersColors[character] = color.hex
      } catch (err) {
        console.error(err)
      }
    },
    changeBackgroundColor(color) {
      let url = `/img/templates/br-${color}-min.png`;
      this.settings.backgroundImage = url
    },
    incrementSpeedRate() {
      let speedRatesAvailables = [0.2, 0.5, 0.75, 1, 1.25, 1.5, 2]
      let index = speedRatesAvailables.findIndex(rate => rate == this.videoSpeedRate)
      if (index == speedRatesAvailables.length - 1) {
        this.videoSpeedRate = speedRatesAvailables[0]
      } else {
        this.videoSpeedRate = speedRatesAvailables[index + 1]
      }
    },
    async audioDenoiser() {
      this.loading = true
      try {
        await this.saveProject()
        await axios.get(`${process.env.VUE_APP_TASK_URI}/denoise/${this.workspace}?region=${this.region}`)
        this.loadWorkspace()
      } catch (err) {
        console.error(err)
        this.error = true
        this.errorMessage = err.message
      }
      this.loading = false
    },
    cutSubtitle(subtitle, firstPart, secondPart) {
      this.subtitles = this.subtitles.filter(sub => sub.id != subtitle.id)
      this.subtitles.push(firstPart)
      this.subtitles.push(secondPart)
    },
    checkFrameratePlan() {
      if (this.$auth?.user?.role != 'pro' && this.settings.framerate > 60) {
        this.openPopupPlan = true
        this.$nextTick(() => {
          this.settings.framerate = 60
          // this.$forceUpdate()
        })
      }
    },
    checkAIAudioPlan() {
      let hasAISources = this.sources.find(map => map.subtitle_id)
      return hasAISources
    },
    async createLanguage(lang) {
      // save project
      await this.saveProject()
      // create language
      await axios.post(`${process.env.VUE_APP_API_URI}/api/workspaces/${this.workspace}/localizations`, {
        locale: lang
      })
      // reload workspace
      this.loadWorkspace()
    },
    deleteTask(id) {
      this.$strapi.delete('tasks', id)
      this.getTasks()
    },
    async loadEvent() {
      console.log('loaded')
    },
    getLevelHeightOfSelectedCharacter() {
      let charactersIndex = this.getArrayOfAvailableCharacter.findIndex(character => character == this.selectedCharacter)
      let height = 0
      if (charactersIndex == 0) {
        height = 0
      } else {
        height = (charactersIndex * 60)
      }
      return height
    },
    async detectSubtitles() {
      this.loading = true;
      try {
        await this.saveProject()
        await axios.get(`${process.env.VUE_APP_TASK_URI}/speechtotext/${this.workspace}?region=${this.region}`)
        this.loadWorkspace()
      } catch (err) {
        console.error(err)
        this.error = true;
        this.errorMessage = err.message;
      }
      this.loading = false;
    },
    async changeSubtitlesLanguage() {
      let lang = this.settings.language
      if (lang == 'fr') {
        this.subtitles = this.workspaceData.attributes.subtitles
        this.sources = this.workspaceData.attributes.audiojson
      } else {
        this.subtitles = this.workspaceData.attributes.localizations.data.find(map => map?.attributes?.locale == lang)?.attributes?.subtitles || []
        let localizedData = this.workspaceData.attributes.localizations.data.find(map => map?.attributes?.locale == lang)
        localizedData = await this.$strapi.findOne('workspaces', localizedData.id, {
          populate: '*'
        })
        localizedData = localizedData.data
        let audiojson = localizedData.attributes.audiojson
        console.log('audiojson for lang', audiojson)
        let sources = audiojson
        if (audiojson) {
          for (let i = 0; i < sources.length; i++) {
            if (!sources[0].id) {
              sources[i].id = i;
            }
            if (!sources[i].muted) {
              sources[i].muted = false;
            }
            // data.attributes.audios.data[0].attributes.name
            if (localizedData.attributes.audios.data.find(audio => audio.attributes.name == sources[i].name)) {
              sources[i].src = localizedData.attributes.audios.data.find(audio => audio.attributes.name == sources[i].name).attributes.url
            }
          }
          this.sources = sources;
        }
      }
    },
    async generateSubtitles() {
      this.loading = true;
      try {
        await this.saveProject();
        await axios.get(`${process.env.VUE_APP_TASK_URI}/translate/${this.workspace}?region=${this.region}&lang=${this.isoLang}`);
        this.loadWorkspace();
      } catch (err) {
        console.error(err)
        this.error = err
        this.errorMessage = err.message
      }
      this.loading = false;
    },
    swipeMove(dir) {
      console.log('editing subtitle', this.isEditingSubtitle);
      if (this.isEditingSubtitle) return;
      let offsetX = (dir.touchmoveX - dir.touchstartX)
      if (offsetX < -100) {
        this.$refs.video.currentTime += 1 / this.base_rate
      } else if (offsetX > 100) {
        this.$refs.video.currentTime -= 1 / this.base_rate
      }
    },
    speakingRateChange($event) {
      this.generateAudio($event.id)
    },
    async generateAudio(id = null) {
      this.loading = true;
      try {
        await this.saveProject();
        await axios.get(`${process.env.VUE_APP_TASK_URI}/vocalize/${this.workspace}?subtitle_id=${id}&lang=${this.isoLang}&region=${this.region}`);
        this.loadWorkspace();
      } catch (err) {
        console.error(err)
        this.error = err
        this.errorMessage = err.message
      }
      this.loading = false;
    },
    muteAudio(sourcesMerged) {
      sourcesMerged.forEach((source) => {
        source.muted = !source.muted;
        this.$refs[`audio${source.character}${source.id}`][0].toggleMute();
      })
    },
    async createExportTask() {
      if(this.checkIfSubtitleIsEmpty()) return
      // if is not pro or basic and isTaskAlreadyExecuted return
      if (this.isTaskAlreadyExecuted) {
        if ((this.$auth?.user?.role != 'basic' && this.$auth?.user?.role != 'pro')) {
          this.openPopupPlan = true;
          this.reasonPlan = 'too_many_tasks';
          return;
        }
      }
      this.zoom = 1;
      this.videoTime = 0;
      this.loading = true;
      this.$nextTick(async () => {
        try {
          this.tasks.push({
            attributes: {
              state: 'queued',
              progress: 0,
              createdAt: new Date()
            }
          })
          this.taskPopup = true;
          await this.saveProject();
          if (this.checkAIAudioPlan()) {
            if ((this.$auth?.user?.role != 'basic' && this.$auth?.user?.role != 'pro')) {
              this.openPopupPlan = true
              return;
            }
          }
          this.saveInProgress = true;
          let options = {
            fps: this.settings.framerate < 60 ? 60 : this.settings.framerate,
            iafilter: this.settings.iafilter,
            tiktokMode: this.exportMode == 'tiktok',
            without_rythmo: this.settings.without_rythmo
          }
          let query = `?fps=${options.fps}&iafilter=${options.iafilter}&tiktokMode=${options.tiktokMode}&without_rythmo=${options.without_rythmo}&region=${this.region}`
          await axios.get(`${process.env.VUE_APP_TASK_URI}/launch-task/${this.workspace}${query}`)
        } catch (err) {
          console.error(err);
          this.error = err;
          this.errorMessage = err.message
        }
        this.loading = false;
        this.saveInProgress = false;
      })
    },
    heightBySeed(seed) {
      let maxValue = this.settings.height - 100;
      let value = (parseInt(seed, 36) % maxValue) + 50;
      if (value < 50) value = 50;
      if (value > maxValue) value = maxValue;
      return value;
    },
    // function to change height of subtitle
    changeToLine(line, subtitle) {
      line = line -1
      console.log('changeToLine', line, subtitle)
      let baseHeight = this.subtitles.find(subtitle => subtitle.character == this.selectedCharacter)?.y || this.heightBySeed(this.selectedCharacter || 'character')
      if (line !== null) baseHeight = line * (this.settings.height / 4)
      if (line == 0) {
        baseHeight = 1
      }
      for (let i = 0; i < this.subtitles.length; i++) {
        if (this.subtitles[i].id == subtitle.id) {
          this.$nextTick(() => {
            this.subtitles[i].y = baseHeight || 0
            console.log('subtitles', this.subtitles[i])
          })
        }
      }
    },
    createSubtitle(text, line = null) {
      let baseHeight = this.subtitles.find(subtitle => subtitle.character == this.selectedCharacter)?.y || this.heightBySeed(this.selectedCharacter || 'character')
      if (line !== null) baseHeight = line * (this.settings.height / 4)
      if (line == 0) {
        baseHeight = 1
      }
      this.subtitles.push({
        start: this.$refs.video.currentTime,
        end: this.$refs.video.currentTime + 1,
        line: 0,
        y: baseHeight || 0,
        id: this.subtitles.length + 1,
        text: text || '',
        underline: false,
        dashed: false,
        exclude: false,
        speedrate: 1,
        male: false,
        female: false,
        character: this.selectedCharacter || 'character',
        active: true,
        characterShow: true
      })
      this.operations.push({
        type: 'subtitle',
        item: {
          id: this.subtitles.length
        }
      });
    },
    undo() {
      let lastOperation = this.operations.pop()
      console.log('lastOperation', lastOperation)
      if (!lastOperation) return
      switch (lastOperation.type) {
        case 'subtitle':
          this.subtitles = this.subtitles.filter(subtitle => subtitle.id != lastOperation.item.id)
          break;
        case 'item':
          this.ndd.items = this.ndd.items.filter(item => item.id != lastOperation.item.id)
          break;
        case 'loop':
          this.ndd.loops = this.ndd.loops.filter(item => item.id != lastOperation.item.id)
          break;
        case 'endDialog':
          this.ndd.endDialog = this.ndd.endDialog.filter(item => item.id != lastOperation.item.id)
          break;
        case 'svg':
          this.ndd.svg = this.ndd.svg.filter(item => item.id != lastOperation.item.id)
          break;
        case 'sceneChange':
          this.ndd.sceneChanges = this.ndd.sceneChanges.filter(item => item.id != lastOperation.item.id)
          break;
        case 'note':
          this.ndd.notes = this.ndd.notes.filter(item => item.id != lastOperation.item.id)
          break;
      }
    },
    loadRenderVideo(src) {
      this.outputURL = null;
      this.$nextTick(() => {
        this.renderDone = true;
        this.outputURL = src;
      })
    },
    newProject() {
      if (this.$route.query.workspace) {
        this.$router.push('/')
      }
      this.workspace = null;
      this.subtitles = [];
      this.sources = [];
      this.ndd = {
        sceneChanges: [],
        notes: [],
        endDialog: [],
        loops: [],
        items: [],
        svg: []
      };
      this.operations = [];
      this.$refs.video.src = '';
    },
    // Audio Handling
    deleteAudioSource(sourceId) {
      this.sources = this.sources.filter(source => source.id !== sourceId);
    },
    handleInput(e, subtitle) {
      // e.preventDefault();
      subtitle.text = e.target.innerText
    },
    addItem(item) {
      this.ndd.items.push({
        id: this.ndd.items.length + 1,
        text: item,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize,
        y: 0
      })
      this.operations.push({
        type: 'item',
        item: {
          id: this.ndd.items.length
        }
      });
    },
    addLoop() {
      this.ndd.loops.push({
        id: this.ndd.loops.length + 1,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize
      })
      this.operations.push({
        type: 'loop',
        item: {
          id: this.ndd.loops.length
        }
      });
    },
    addEndDialog() {
      this.ndd.endDialog.push({
        id: this.ndd.endDialog.length + 1,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize
      })
      this.operations.push({
        type: 'endDialog',
        item: {
          id: this.ndd.endDialog.length
        }
      });
    },
    addSvg(id) {
      this.ndd.svg.push({
        id: this.ndd.svg.length + 1,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize,
        y: 0,
        svg: id
      })
      this.operations.push({
        type: 'svg',
        item: {
          id: this.ndd.svg.length
        }
      });
    },
    addSceneChange() {
      this.ndd.sceneChanges.push({
        id: this.ndd.sceneChanges.length + 1,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize
      })
      this.operations.push({
        type: 'sceneChange',
        item: {
          id: this.ndd.sceneChanges.length
        }
      });
    },
    addNote(text) {
      this.ndd.notes.push({
        id: this.ndd.notes.length + 1,
        x: this.$refs.video.currentTime * (25) * this.settings.fontSize,
        y: 0,
        text: text
      })
      this.operations.push({
        type: 'note',
        item: {
          id: this.ndd.notes.length
        }
      });
    },
    scrollToTimeline(event) {
      if (event.target.id == 'timeline' || event.target.id == 'offsetDivAudio') {
        let scroll = (event.offsetX / event.target.offsetWidth) * this.videoDuration
        this.$refs.video.currentTime = scroll - (this.offsetDuration)
        this.videoTime = scroll
      }
    },
    scrollTo() {
      // return; originaly scrollTo(subtitle)
      // this.$nextTick(() => {
      //   const overflow = this.$refs.scrollableSub
      //   const anchor = document.getElementById(`su${subtitle}`)
      //   console.log(`su${gitsubtitle}`, anchor)
      //   overflow.scrollTop = anchor.offsetTop - overflow.offsetTop
      // })
    },
    applyStyle(style, subtitle) {
      if (style == 'underline') {
        subtitle.underline = !subtitle.underline
      }
      if (style == 'dashed') {
        subtitle.dashed = !subtitle.dashed
      }
    },
    bufferToWave(abuffer, offset, len) {
      var numOfChan = abuffer.numberOfChannels,
        length = len * numOfChan * 2 + 44,
        buffer = new ArrayBuffer(length),
        view = new DataView(buffer),
        channels = [], i, sample,
        pos = 0;
      // write WAVE header
      setUint32(0x46464952);                         // "RIFF"
      setUint32(length - 8);                         // file length - 8
      setUint32(0x45564157);                         // "WAVE"
      setUint32(0x20746d66);                         // "fmt " chunk
      setUint32(16);                                 // length = 16
      setUint16(1);                                  // PCM (uncompressed)
      setUint16(numOfChan);
      setUint32(abuffer.sampleRate);
      setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
      setUint16(numOfChan * 2);                      // block-align
      setUint16(16);                                 // 16-bit (hardcoded in this demo)
      setUint32(0x61746164);                         // "data" - chunk
      setUint32(length - pos - 4);                   // chunk length
      // write interleaved data
      for (i = 0; i < abuffer.numberOfChannels; i++)
        channels.push(abuffer.getChannelData(i));
      while (pos < length) {
        for (i = 0; i < numOfChan; i++) {             // interleave channels
          sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
          sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
          view.setInt16(pos, sample, true);          // update data chunk
          pos += 2;
        }
        offset++                                     // next source sample
      }
      // create Blob
      return new Blob([buffer], { type: "audio/mpeg" });
      function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
      }
      function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
      }
    },
    async recordAudio(i = false) {
      if (i) {
        this.selectedCharacter = i;
      }
      if (this.isRecording) return;
      this.bottomTab = 1;
      window.gtag('event', 'record_audio_global')
      const stream = await this.getLocalMediaStream();
      console.log(stream)
      this.isRecording = true;
      let startVideo = this.$refs.video.currentTime;
      this.startRecording = this.$refs.video.currentTime;
      if (startVideo < 0) {
        startVideo = 0;
      }
      this.$refs.video.currentTime = startVideo;
      // timer countdown before recording
      this.timer = 3;
      let timerInterval = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(timerInterval);
        }
      }, 1000);
      // await 3 sec
      var extension;
      await new Promise(resolve => setTimeout(resolve, (3000)));
      // Optional frames per second argument.
      if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
        extension = "audio/webm;codecs=opus";
      } else {
        if (MediaRecorder.isTypeSupported('audio/ogg;codecs=opus')) {
          extension = "audio/ogg;codecs=opus"
        } else {
          extension = "video/mp4"
        }
      }
      this.recordedChunks = [];
      var options = {
        mimeType: extension
      }
      this.mediaRecorder = new MediaRecorder(stream, options);
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start(25);
      this.recordStart = this.$refs.video.currentTime;
      this.$refs.video.play();
    },
    selectCharacterAudio(i) {
      this.resetselectedSubtitle();
      this.$nextTick(() => {
        this.selectedCharacter = i;
      })
    },
    async getAudioBuffer(src, start, end) {
      // Create an offline context 
      // OfflineAudioContext(destination, length, sampleRate)
      // length 
      var offlineContext = new OfflineAudioContext(2, 44100 * (end - start), 44100);
      // Create buffer source
      var bufferSource = offlineContext.createBufferSource();
      // Load buffer asynchronously
      var request = new XMLHttpRequest();
      request.open('GET', src, true);
      request.responseType = 'arraybuffer';
      request.onload = function () {
        offlineContext.decodeAudioData(request.response, function (buffer) {
          bufferSource.buffer = buffer;
          bufferSource.connect(offlineContext.destination);
          bufferSource.start(0);
          offlineContext.startRendering();
        }, function (e) { "Error with decoding audio data" + e.err });
      }
      request.send();
      // Return a promise
      return new Promise(function (resolve, reject) {
        offlineContext.oncomplete = function (e) {
          resolve(e.renderedBuffer);
        }
        offlineContext.onerror = function () {
          reject("OfflineAudioContext error");
        }
      });
    },
    async copyAudioBuffer(buffer, start, end) {
      var segmentDuration = end - start
      var originalBuffer = buffer
      // originalBuffer.numberOfChannels,
      // segmentDuration * originalBuffer.sampleRate,
      // originalBuffer.sampleRate
      var emptySegment = new AudioBuffer({
        length: segmentDuration * originalBuffer.sampleRate,
        numberOfChannels: originalBuffer.numberOfChannels,
        sampleRate: originalBuffer.sampleRate
      });
      for (var i = 0; i < originalBuffer.numberOfChannels; i++) {
        var chanData = originalBuffer.getChannelData(i);
        var emptySegmentData = emptySegment.getChannelData(i);
        var mid_data = chanData.subarray(start * originalBuffer.sampleRate, end * originalBuffer.sampleRate);
        emptySegmentData.set(mid_data);
      }
      var arraybuffer = await toWav(emptySegment)
      return (new Blob([arraybuffer], { type: 'audio/wav' }));
    },
    async decouperSourcesChevauchantes(nouvelleSource, sources, selectedCharacter) {
      let sourcesDecoupees = [];
      for (let i = 0; i < sources.length; i++) {
        let source = sources[i];
        try {
          // Si la source chevauche la nouvelle source
          if (source.start < nouvelleSource.end && source.end > nouvelleSource.start && source.character == selectedCharacter) {
            // Découper la source chevauchante en deux parties distinctes
            if (source.start < nouvelleSource.start) {
              let partieAvant = {
                ...source,
                end: nouvelleSource.start
              };
              let buffer = await this.getAudioBuffer(source.src, source.start, source.end);
              let cutAudioBuffer = await this.copyAudioBuffer(buffer, 0, nouvelleSource.start - source.start);
              partieAvant.src = URL.createObjectURL(cutAudioBuffer);
              sourcesDecoupees.push(partieAvant);
            }
            if (source.end > nouvelleSource.end && selectedCharacter == nouvelleSource.character) {
              let partieApres = {
                ...source,
                start: nouvelleSource.end
              };
              let buffer = await this.getAudioBuffer(source.src, source.start, source.end);
              let cutAudioBuffer = await this.copyAudioBuffer(buffer, nouvelleSource.end - source.start, source.end - source.start);
              partieApres.src = URL.createObjectURL(cutAudioBuffer);
              sourcesDecoupees.push(partieApres);
            }
          } else {
            sourcesDecoupees.push(source);
          }
        } catch (err) {
          console.log('err', err)
          sourcesDecoupees.push(source);
        }
      }
      return sourcesDecoupees;
    },
    async stopRecording() {
      this.$refs.video.pause();
      this.isRecording = false;
      this.mediaRecorder.stop();
      let blob = new Blob(this.recordedChunks, {
        type: "video/webm"
      });
      if (!this.selectedCharacter) {
        this.selectedCharacter = this.getArrayOfAvailableCharacter[0]
      }
      var mediaElt = document.createElement('video');
      mediaElt.src = URL.createObjectURL(blob);
      this.recordedChunks = [];
      let newSource = {
        id: this.sources.length + 1,
        name: this.$moment().unix(),
        character: this.selectedCharacter,
        duration: (this.$refs.video.currentTime - this.recordStart),
        start: (this.recordStart - .1) < 0 ? 0 : (this.recordStart - .1),
        end: this.$refs.video.currentTime,
        src: mediaElt.src
      }
      // trouver les sources qui chevauche la nouvelle source et les découper en 2
      this.sources = await this.decouperSourcesChevauchantes(newSource, this.sources, this.selectedCharacter);
      this.sources.push(newSource);
      // reset source index
      this.sources = this.sources.map((source, index) => {
        source.id = index + 1;
        return source;
      })
      this.isRecording = false;
    },
    async speechReconitionInit() {
      // Récupérer la balise vidéo
      let video = this.$refs.video;
      let startVideo = this.videoTime - .5;
      if (startVideo < 0) {
        startVideo = 0;
      }
      this.$refs.video.currentTime = startVideo;
      // timer countdown before recording
      this.timer = 3;
      let timerInterval = setInterval(() => {
        this.timer--;
        if (this.timer === 0) {
          clearInterval(timerInterval);
        }
      }, 1000);
      window.gtag('event', 'speech_recognition')
      let start = 0;
      let end = 0;
      // Vérifie si l'API SpeechRecognition est prise en charge par le navigateur
      if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
        this.speechRecognition = true;
        // Créer un nouvel objet SpeechRecognition
        let recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        // Définir la langue de reconnaissance
        recognition.lang = 'fr-FR';
        // Écouter les événements de résultat de reconnaissance vocale
        recognition.onresult = (event) => {
          // Obtenir le résultat de la reconnaissance vocale sous forme de texte
          const transcription = event.results[0][0].transcript;
          end = video.currentTime;
          this.subtitles.push({
            id: this.subtitles.length + 1,
            active: true,
            underline: false,
            dashed: false,
            y: this.heightBySeed(this.selectedCharacter || 'character') || 0,
            line: 0,
            exclude: false,
            male: false,
            female: false,
            speedrate: 1,
            start: start,
            end: end,
            character: this.selectedCharacter || 'character',
            text: transcription
          })
          this.speechRecognition = false;
        };
        // Écouter les événements d'erreur de reconnaissance vocale
        recognition.onerror = (err) => {
          console.log('err', err)
          this.$refs.video.pause();
        };
        // Écouter l'événement "play" de la vidéo
        this.$refs.video.addEventListener('play', () => {
          if (!video) {
            video = this.$refs.video;
          }
          if (!video) return;
          start = video.currentTime;
          // Démarrer la reconnaissance vocale lorsque la vidéo commence à jouer
          if (this.speechRecognition == true) {
            recognition.start();
          }
        });
        // Écouter l'événement "pause" de la vidéo
        this.$refs.video.addEventListener('pause', () => {
          // Arrêter la reconnaissance vocale lorsque la vidéo est en pause
          recognition.stop();
          this.speechRecognition = false;
          this.$refs.baseaudio.pause();
        });
        // await 3 sec
        await new Promise(resolve => setTimeout(resolve, (3000)));
        this.$refs.video.play();
        await new Promise(resolve => setTimeout(resolve, (.5 * 1000)));
      }
    },
    enableDeleteItems() {
      this.deleteItems = !this.deleteItems;
    },
    openExportWindow() {
      this.popupExport = true;
    },
    async denoiseAudioData() {
      var adjust = 2;
      const videoElt = this.$refs.video
      // wait 5 sec 
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.$refs.video.play();
      const audioContext = new AudioContext();
      const sourceNode = audioContext.createMediaElementSource(videoElt);
      const processorNode = audioContext.createScriptProcessor(4096);
      processorNode.onaudioprocess = (e) => {
        var inp0 = e.inputBuffer.getChannelData(0);
        var inp1 = e.inputBuffer.getChannelData(1);
        var out0 = e.outputBuffer.getChannelData(0);
        var out1 = e.outputBuffer.getChannelData(1);
        var i
        if (this.denoiseAudio) {
          for (i = 0; i < inp0.length; i++) {
            out0[i] = (inp0[i] - inp1[i]) / 2 * adjust;
            out1[i] = (inp1[i] - inp0[i]) / 2 * adjust;
          }
        } else {
          for (i = 0; i < inp0.length; i++) {
            out0[i] = inp0[i];
            out1[i] = inp1[i];
          }
        }
      };
      sourceNode.connect(processorNode);
      processorNode.connect(audioContext.destination);
    },
    async getAudioData() {
      // axios arraybuffer
      let audioSource = await fetch(this.$refs.video.src, { responseType: 'arraybuffer' }).then(r => r.blob());
      if (!this.sources.find(s => s.character == 'all')) {
        this.sources.push({
          id: this.sources.length + 1,
          name: this.$moment().unix(),
          character: 'all',
          duration: this.videoDuration * this.timelineSize,
          start: 0,
          end: this.videoDuration,
          src: URL.createObjectURL(audioSource)
        })
      }
    },
    handleDataAvailable(event) {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    },
    async getLocalMediaStream() {
      let mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      return mediaStream;
    },
    // Subtitle Handling
    startDragSubtitle(subtitle, type) {
      this.resetselectedSubtitle();
      this.selectedSubtitle = subtitle;
      var end = subtitle[type];
      this.baseDraggingX = end
      this.selectedSubtitleType = type;
      this.isDragging = this.selectedSubtitle.id;
    },
    dragEnd(event) {
      this.newTime = this.baseDraggingX + (event.offsetX / 100);
      this.isDragging = false;
      this.selectedSubtitle[this.selectedSubtitleType] = this.newTime;
      this.newTime = false;
      this.resetselectedSubtitle();
    },
    resetSubtitles() {
      this.subtitles = [];
    },
    resetselectedSubtitle() {
      this.selectedSubtitle = { id: null };
    },
    // seconds = 0.00
    transformSecondsToTime(time, format) {
      // output format 00:00:00,000 without $moment
      if (format == '00:00:00,000') {
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time - (hours * 3600)) / 60);
        let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
        let milliseconds = Math.floor((time - Math.floor(time)) * 1000);
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        if (milliseconds < 10) { milliseconds = "00" + milliseconds; }
        else if (milliseconds < 100) { milliseconds = "0" + milliseconds; }
        return hours + ':' + minutes + ':' + seconds + ',' + milliseconds;
      } else if (format == '0:00:00.00') {
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time - (hours * 3600)) / 60);
        let seconds = Math.floor(time - (hours * 3600) - (minutes * 60));
        let milliseconds = Math.floor((time - Math.floor(time)) * 100);
        if (hours < 10) { hours = "0" + hours; }
        if (minutes < 10) { minutes = "0" + minutes; }
        if (seconds < 10) { seconds = "0" + seconds; }
        if (milliseconds < 10) { milliseconds = "00" + milliseconds; }
        else if (milliseconds < 100) { milliseconds = "0" + milliseconds; }
        return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
      }
    },
    exportToSrt() {
      let srttext = ''
      this.subtitles.forEach((subtitle, index) => {
        srttext += `${index + 1}\n`;
        // format is 01:MM:ss,SSS
        let start = this.transformSecondsToTime(subtitle.start, '00:00:00,000')
        let end = this.transformSecondsToTime(subtitle.end, '00:00:00,000')
        // want 00:MM:ss,SSS
        srttext += `${start} --> ${end}\n`;
        srttext += `${subtitle.text}\n\n`;
      })
      let dataStr = "data:text/srt;charset=utf-8," + encodeURIComponent(srttext);
      let downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "subtitles.srt");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
    },
    exportToAss() {
      let asstext = ''
      asstext += '[Script Info]\n'
      asstext += '; Script generated by Voxdub\n'
      asstext += '; https://voxdub.com/\n'
      asstext += 'PlayResY: 600\n'
      asstext += 'WrapStyle: 2\n'
      asstext += '\n'
      asstext += '[V4+ Styles]\n'
      asstext += 'Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding\n'
      asstext += 'Style: Code,monospace,20,&H00B0B0B0,&H00B0B0B0,&H00303030,&H80000008,-1,0,0,0,100,100,0,0,1,1,2,7,30,10,30,0\n'
      asstext += 'Style: Expl,Arial,28,&H00FFB0B0,&H00B0B0B0,&H00303030,&H80000008,-1,0,0,0,100,100,0,0,1,1,2,7,30,10,30,0\n'
      asstext += '\n'
      // [Events]
      // Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text
      asstext += '[Events]\n'
      asstext += 'Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text\n'
      this.subtitles.forEach((subtitle) => {
        // format is 01:MM:ss,SSS
        let start = this.transformSecondsToTime(subtitle.start, '0:00:00.00')
        let end = this.transformSecondsToTime(subtitle.end, '0:00:00.00')
        // want 00:MM:ss,SSS
        asstext += `Dialogue: 1,${start},${end},Default,${subtitle.character},0,0,0,,${subtitle.text}\n`
      })
      let dataStr = "data:text/ass;charset=utf-8," + encodeURIComponent(asstext);
      let downloadAnchorNode = document.createElement("a")
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "subtitles.ass");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
    },
    exportProject() {
      let json = {
        subtitles: this.subtitles,
        ndd: this.ndd
      }
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
      let downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "project.json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
    },
    importProject() {
      // create file input
      let fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.json';
      document.body.appendChild(fileInput);
      fileInput.onchange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = (e) => {
        let json = JSON.parse(e.target.result);
        this.subtitles = json.subtitles;
          this.ndd = json.ndd;
        }
        reader.readAsText(file);
      }
      fileInput.click();
    },
    exportToJson() {
      window.gtag('event', 'export_subtitles')
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.subtitles));
      let downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "subtitles.json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    parseAss(text) {
      let subtitles = [];
      let lines = text.split('\n');
      for (const line of lines) {
        if (line.startsWith('Dialogue:')) {
          let parts = line.split(',');
          let start = this.transformTimeToSeconds(parts[1], '0:00:00.00');
          let end = this.transformTimeToSeconds(parts[2], '0:00:00.00');
          let character = parts[4];
          let text = parts[9];
          subtitles.push({ start, end, character, text });
        }
      }
      return subtitles;
    },
    transformTimeToSeconds(time, format) {
      let parts = time.split(':');
      let hours = 0;
      let minutes = 0;
      let seconds = 0;
      let milliseconds = 0;
      if (format == '00:00:00,000') {
        hours = parseInt(parts[0]);
        minutes = parseInt(parts[1]);
        seconds = parseInt(parts[2].split(',')[0]);
        milliseconds = parseInt(parts[2].split(',')[1]);
      } else if (format == '0:00:00.00') {
        hours = parseInt(parts[0]);
        minutes = parseInt(parts[1]);
        seconds = parseInt(parts[2].split('.')[0]);
        milliseconds = parseInt(parts[2].split('.')[1]) * 10;
      }
      return hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
    },
    parseSrt(text) {
      let subtitles = [];
      let lines = text.split('\n');
      for (const line of lines) {
        if (line.match(/\d\d:\d\d:\d\d,\d\d\d --> \d\d:\d\d:\d\d,\d\d\d/)) {
          let parts = line.split(' --> ');
          let start = this.transformTimeToSeconds(parts[0], '00:00:00,000');
          let end = this.transformTimeToSeconds(parts[1], '00:00:00,000');
          let text = '';
          let character = '';
          let nextLine = lines[lines.indexOf(line) + 1];
          if (nextLine.startsWith('[')) {
            let characterLine = nextLine.split(']');
            character = characterLine[0].replace('[', '');
            text = characterLine[1];
          } else {
            text = nextLine;
          }
          subtitles.push({ start, end, character, text });
        }
      }
      return subtitles;
    },
    importFromJson(event) {
      window.gtag('event', 'import_subtitles')
      let file = event.target.files[0];
      //get file extensions
      let fileName = file.name;
      let fileExtension = fileName.split('.').pop();
      let reader = new FileReader();
      reader.onload = (e) => {
        if (fileExtension.toLowerCase() == 'json') {
          this.subtitles = JSON.parse(e.target.result);
        } else if (fileExtension.toLowerCase() == 'srt') {
          this.subtitles = this.parseSrt(e.target.result);
        } else if (fileExtension.toLowerCase() == 'ass') {
          this.subtitles = this.parseAss(e.target.result);
        }
      };
      reader.readAsText(file);
    },
    selectSubtitle(id, type) {
      this.resetselectedSubtitle();
      this.selectedSubtitle = this.subtitles.find(subtitle => subtitle.id == id);
      this.selectedSubtitle.type = type;
    },
    deleteSubtitle(id) {
      this.subtitles = this.subtitles.filter(subtitle => subtitle.id != id);
      this.$nextTick(() => {
        this.resetselectedSubtitle();
      })
    },
    // Project Handling
    async saveProject() {
      window.gtag('event', 'save_project')
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11106758945/KXgQCPSZ8q0YEKHijrAp'
      })
      this.title = this.title.replace(/[()]/g, '');
      let config = {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          this.uploadProgress = percentCompleted;
        }
      }
      this.saveInProgress = true;
      if (!this.sources.find(map => map.character == 'all')) {
        // if video source = base64 then convert to blob
        let videoSource = this.$refs.video.src;
        if (videoSource.includes('base64')) {
          let blob = await fetch(videoSource).then(r => r.blob());
          videoSource = URL.createObjectURL(blob)
        }
        this.sources.push({
          id: this.sources.length + 1,
          name: this.$moment().unix(),
          character: 'all',
          duration: this.videoDuration * this.timelineSize,
          start: 0,
          end: this.videoDuration,
          src: videoSource
        })
      }
      // remove if we have twice character all
      let alreadyHaveCharacterAll = false;
      this.sources = this.sources.filter(map => {
        if (!this.getArrayOfAvailableCharacter.includes(map.character) && map.character != 'all') {
          return false;
        }
        if (!alreadyHaveCharacterAll && map.character == 'all') {
          alreadyHaveCharacterAll = true;
          return true;
        } else if (map.character != 'all') {
          return true;
        }
      })
      console.log('this.sources', this.sources)
      try {
        if (this.workspace && !this.publicWorkspace && this.workspaceData.attributes.user == this.$auth.user.sub) {
          let data = new FormData();
          // create file object from src
          // let blob = await fetch(this.$refs.video.src).then(r => r.blob());
          // clean audios from workspace
          let id = this.workspace
          await this.$strapi.update('workspaces', id, {
            settings: this.settings
          })
          if (this.settings.language != 'fr' && this.settings.language != null) {
            id = this.workspaceData.attributes?.localizations?.data.find(l => l.attributes.locale == this.settings.language).id
          }
          // data.append('files.video', blob);
          data.append('data', JSON.stringify({
            subtitles: this.subtitles,
            audiojson: this.sources,
            user: this.$auth.user.sub,
            settings: this.settings,
            ndd: this.ndd,
            title: this.title,
          }));
          let audios = this.sources;
          for (let i = 0; i < audios.length; i++) {
            if (audios[i].character != 'all') {
              try {
                let blob = await fetch(audios[i].src).then(r => r.blob());
                data.append(`files.audios`, blob, audios[i].name);
              } catch (err) {
                console.log(err);
              }
            }
          }
          let url = this.$strapi.options.url
          // get upload progress
          await axios.put(`${url}/api/workspaces/${id}`,
            data
            , config)
        } else {
          if (this.$auth?.user?.role != 'basic' && this.$auth?.user?.role != 'pro') {
            if (this.user.projects.length >= 5) {
              this.error = true;
              this.errorMessage = 'You have reached the maximum number of projects'
              this.openPopupPlan = true;
              // return;
            }
          } else if (this.$auth?.user?.role == 'basic') {
            if (this.user.projects.length >= 10) {
              this.error = true;
              this.errorMessage = 'You have reached the maximum number of projects'
              this.openPopupPlan = true;
              // return;
            }
          }
          let data = new FormData();
          let blob = await fetch(this.$refs.video.src).then(r => r.blob());
          data.append('files.video', blob);
          data.append('data', JSON.stringify({
            subtitles: this.subtitles,
            audiojson: this.sources,
            user: this.$auth.user.sub,
            settings: this.settings,
            locale: this.settings.language,
            ndd: this.ndd,
            title: this.title,
          }));
          let audios = this.sources;
          for (let i = 0; i < audios.length; i++) {
            try {
              let blob = await fetch(audios[i].src).then(r => r.blob());
              data.append(`files.audios`, blob, audios[i].name);
            } catch (err) {
              console.log(err);
            }
          }
          let url = this.$strapi.options.url
          let createWokspace = await axios.post(`${url}/api/workspaces`,
            data
            , config
          )
          this.workspace = createWokspace.data.data.id;
          this.$router.push(`/project?workspace=${createWokspace.data.data.id}`)
          this.publicWorkspace = false;
        }
      } catch (err) {
        this.saveInProgress = false;
        this.error = err
        console.error('err', err)
        this.$sentry.captureException(err.message)
        this.errorMessage = this.$t('error.internet_or_space_left', { err: err.message })
      }
      this.saveInProgress = false;
      // this.$refs.renderRythmo.scrollLeft = 0;
      this.chunkSize = this.maxSize;
      this.lastSave = this.$moment().unix();
      // event bus emit
      this.$bus.$emit('updateProjects');
    },
    async loadWorkspace() {
      this.videoLoading = true;
      window.gtag('event', 'load_workspace')
      let entry = await this.$strapi.findOne('workspaces', this.workspace, {
        populate: '*'
      });
      this.workspaceData = entry.data;
      if (entry.data.attributes.user != this.$auth?.user?.sub && !entry.data.attributes.public) {
        console.log('current user', this.$auth?.user?.sub)
        // check in settings if user can access to workspace
        if (entry.data.attributes.settings?.access?.find(user => user == this.$auth?.user?.sub)) {
          console.log("can access")
        } else {
          console.log("can't access")
          this.confirmLeave = true;
          // this.$router.push('/workspaces')
        }
      }
      this.lastSave = this.$moment(entry.data.attributes.updatedAt).unix();
      this.publicWorkspace = entry.data.attributes.public;
      if (this.$refs.video) {
        this.$refs.video.src = entry.data.attributes.video.data.attributes.url
      }
      let audiojson = entry.data.attributes.audiojson;
      this.settings = {
        ...this.settings,
        ...entry.data.attributes.settings
      };
      if (this.settings.framerate < 60) {
        this.settings.framerate = 60;
      }
      this.title = entry.data.attributes.title || this.title;
      this.ndd = entry.data.attributes.ndd || this.ndd;
      // if ndd is type of array then convert to object
      if (Array.isArray(this.ndd)) {
        this.ndd = {
          "svg": [],
          "items": [],
          "loops": [],
          "endDialog": [],
          "notes": [],
          "sceneChanges": []
        }
      }
      if (!this.ndd.sceneChanges) {
        this.ndd.sceneChanges = []
      }
      if (!this.ndd.notes) {
        this.ndd.notes = []
      }
      if (!this.ndd.endDialog) {
        this.ndd.endDialog = []
      }
      // audiojson reverse
      let sources = audiojson
      if (audiojson) {
        for (let i = 0; i < sources.length; i++) {
          if (!sources[0].id) {
            sources[i].id = i;
          }
          if (!sources[i].muted) {
            sources[i].muted = false;
          }
          // data.attributes.audios.data[0].attributes.name
          if (entry.data.attributes.audios?.data?.find(audio => audio.attributes.name == sources[i].name)) {
            sources[i].src = entry.data.attributes.audios.data.find(audio => audio.attributes.name == sources[i].name).attributes.url
          }
        }
        this.sources = sources;
      }
      let subtitles = []
      entry.data.attributes.subtitles.forEach((subtitle, index) => {
        if (!subtitle.underline) {
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            underline: false
          });
        }
        if (!subtitle.dashed) {
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            dashed: false
          });
        }
        if (!subtitle.male) {
          // subtitle.male = false;
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            male: false
          });
        }
        if (!subtitle.female) {
          // subtitle.female = false;
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            female: false
          });
        }
        if (!subtitle.speedrate) {
          // subtitle.speedrate = 1;
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            speedrate: 1
          });
        }
        if (!subtitle.exclude) {
          // subtitle.exclude = false;
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            exclude: false
          });
        }
        if (!subtitle.line) {
          // subtitle.line = 0;
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            line: 0
          });
        }
        if (!subtitle.y) {
          // subtitle.y = this.heightBySeed(subtitle.character || 'character')
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            y: this.heightBySeed(subtitle.character || 'character')
          });
        }
        if (subtitle.characterShow === undefined) {
          this.$set(entry.data.attributes.subtitles, index, {
            ...subtitle,
            characterShow: true
          });
        }
        subtitles.push(subtitle)
      })
      console.log('subtitles', subtitles)
      this.subtitles = entry.data.attributes.subtitles
      this.videoSource = entry.data.attributes.video.data.attributes.url;
      this.$nextTick(() => {
        this.getVideoData();
        this.changeSubtitlesLanguage()
      })
    },
    async getTasks() {
      if (this.loading) return;
      await this.$strapi.find('tasks', {
        populate: '*',
        filters: {
          workspace: this.workspace
        },
        sort: 'createdAt:desc',
      }).then(res => {
        this.$store.dispatch('setTasks', res.data);
      })
    },
    async exportAudiosForBwf() {
      this.loading = true;
      try {
        let ffmpeg = createFFmpeg({
          log: true
        });
        await ffmpeg.load();
        // ffmpeg -i ./VoxDub.wav -c copy -write_bext 1 -metadata time_reference=10 output.wav
        let audios = this.sources;
        let output = [];
        for (const audio of audios) {
          let { start } = audio;
          let frequency = 48000;
          let echantillon = start * frequency;
          await ffmpeg.FS('writeFile', `${audio.name}.webm`, await fetchFile(audio.src));
          let cmd = [
            '-i', `${audio.name}.webm`,
            '-write_bext', '1',
            '-metadata', `time_reference=${echantillon}`,
            '-c:a', 'pcm_f32le',
            `${audio.name}.wav`
          ]
          await ffmpeg.run(...cmd);
          const data = ffmpeg.FS('readFile', `${audio.name}.wav`);
          output.push({
            name: audio.name,
            data
          })
        }
        // create zip file
        const zip = new JSZip();
        for (const file of output) {
          zip.file(`${file.name}.wav`, file.data);
        }
        const zipFile = await zip.generateAsync({ type: 'blob' });
        saveAs(zipFile, 'audios.zip');
      } catch (err) {
        console.error(err)
        this.error = err;
        this.errorMessage = err.message;
      }
      this.loading = false;
    },
    loadVideoBlob(event) {
      try {
        this.title = 'New project'
        this.loading = true;
        // this.$router.push('/')
        this.workspace = null;
        this.subtitles = [];
        this.sources = [];
        this.$refs.video.src = '';
        let file = event.target.files[0];
        let reader = new FileReader();
        // Store video in localstorage
        reader.onload = (e) => {
          this.videoSource = e.target.result;
          this.createSubtitle('Text here');
          this.loading = false;
        };
        reader.readAsDataURL(file);
      } catch (err) {
        console.log(err);
      }
    },
    downloadRenderVideo() {
      let videoUrl = this.outputURL;
      let fileName = this.title + '.mp4';
      saveAs(videoUrl, fileName)
    },
    updateScrollPosition() {
      // if video paused return
      const scroll = ((this.videoTime / this.videoDuration) * ((this.videoDuration * 25) * this.settings.fontSize)) * 1000;
      // smooth scroll
      if (!this.$refs.offsetDiv) return;
      if (!this.$refs.offsetDivAudio) return;
      this.$refs.offsetDiv.scrollLeft = scroll / 1000;
      this.$refs.offsetDivAudio.scrollLeft = scroll / 1000;
      let sourcesAudio = this.sources.filter(source => parseFloat(source.start) <= parseFloat(this.$refs.video.currentTime) && parseFloat(source.end) >= parseFloat(this.$refs.video.currentTime));
      let sourcesOff = this.sources.filter(source => parseFloat(source.start) > parseFloat(this.$refs.video.currentTime) || parseFloat(source.end) < parseFloat(this.$refs.video.currentTime));
      if (this.videoPaused) {
        for (const source of sourcesAudio) {
          // WaveSurfer
          if (this.$refs[`audio${source.character}${source.id}`]) {
            if (this.$refs[`audio${source.character}${source.id}`][0]) {
              this.$refs[`audio${source.character}${source.id}`][0].pause();
            }
          }
        }
      } else {
        for (const source of sourcesOff) {
          if (this.$refs[`audio${source.character}${source.id}`]) {
            if (this.$refs[`audio${source.character}${source.id}`][0]) {
              this.$refs[`audio${source.character}${source.id}`][0].pause();
            }
          }
        }
        for (const source of sourcesAudio) {
          let playTime = this.$refs.video.currentTime - source.start;
          if (this.$refs[`audio${source.character}${source.id}`]) {
            if (source.character != 'all') {
              !this.$refs[`audio${source.character}${source.id}`][0].isPlaying() ? this.$refs[`audio${source.character}${source.id}`][0].play(playTime) : null;
            }
          }
        }
      }
      requestAnimationFrame(this.updateScrollPosition.bind(this));
    },
    syncAudio() {
      if (!this.$refs.video.paused) {
        // Play audio source if it exists
        let sources = this.sources.filter(source => this.$refs.video.currentTime >= source.start && this.$refs.video.currentTime <= source.end);
        for (const source of sources) {
          // if (source.character == 'all') continue
          if (!this.$refs[`audio${source.character}${source.subtitle || ''}`]) continue
          if (this.$refs[`audio${source.character}${source.subtitle || ''}`][0].paused) {
            if (source.start == 0) {
              this.$refs[`audio${source.character}${source.subtitle || ''}`][0].currentTime = this.$refs.video.currentTime;
            } else {
              this.$refs[`audio${source.character}${source.subtitle || ''}`][0].currentTime = 0;
            }
            if (this.isRecording) continue;
            if (source.character != 'all') {
              this.$refs[`audio${source.character}${source.subtitle || ''}`][0].play();
            }
          }
        }
        this.selectedSubtitle = { id: null };
      } else {
        // Pause audio source if it exists
        for (const source of this.sources) {
          if (!this.$refs[`audio${source.character}${source.subtitle || ''}`]) continue
          if (!this.$refs[`audio${source.character}${source.subtitle || ''}`][0].paused) {
            this.$refs[`audio${source.character}${source.subtitle || ''}`][0].pause();
          }
        }
      }
    },
    getVideoData() {
      // Get the video duration
      this.$refs.video.addEventListener("loadedmetadata", async (e) => {
        this.$nextTick(() => {
          this.videoLoading = false;
        })
        this.videoDuration = e.target.duration;
        const updateVideoTime = () => {
          // this.videoTime = this.$refs.video.currentTime.toFixed(10);
          // accurate frame time
          if (!this.$refs.video) return;
          this.videoTime = this.$refs.video.currentTime;
          requestAnimationFrame(updateVideoTime);
        };
        updateVideoTime();
      })
      this.$nextTick(() => {
        requestAnimationFrame(this.updateScrollPosition.bind(this));
        this.$refs.video.addEventListener("play", () => {
          console.log(this.$refs.baseaudio)
          this.$refs.baseaudio.setVolume(0);
          this.$refs.baseaudio.play(); 
        })
        this.$refs.video.addEventListener("pause", () => {

          this.$refs.baseaudio.pause();
          // stop all audio sources
          for (const source of this.sources) {
            if (source.character == 'base') continue
            if (!this.$refs[`audio${source.character}${source.subtitle || ''}`]) continue
            this.$refs[`audio${source.character}${source.subtitle || ''}`][0].pause();
          }
        });
      })
    },
    // Misc
    idFromCharacterName(character) {
      let keys = Object.keys(this.subtitlesGroupedByCharacters);
      let characterIndex = keys.indexOf(character);
      return characterIndex;
    },
    choseColorBySeedString(character) {
      // Find character object key index in subtitlesGroupedByCharacters
      let keys = Object.keys(this.subtitlesGroupedByCharacters);
      let characterIndex = keys.indexOf(character);
      if (this.settings.backCharactersColors[character]) {
        return this.settings.backCharactersColors[character];
      }
      let colors = this.darkMode ? [
        "rgb(0 0 0)",
        "rgb(30 30 30)",
      ] : [
        "rgb(255 255 255)",
        "rgb(225 225 225)"
      ]
      // odd or even
      return colors[characterIndex % 2 == 0 ? 0 : 1];
    },
    calcMarginLeftAndWidth(startTime, endTime) {
      let marginLeft = (startTime / (this.videoDuration)) * 100;
      if (marginLeft < 0) {
        marginLeft = 0;
      }
      let width = ((endTime - startTime) / (this.videoDuration)) * 100;
      return `margin-left:${marginLeft}%;width:${width}%`;
    },
    calcMarginLeftAndWidthFun(startTime, endTime) {
      let marginLeft = (startTime / (this.videoDuration)) * 100;
      let width = ((endTime - startTime) / (this.videoDuration)) * 100;
      return {
        marginLeft: `${marginLeft}`,
        width: `${width}`
      }
    },
    calcTransformScaleX(startTime, endTime, text) {
      const mainContainerWidth = (this.videoDuration * 25) * this.settings.fontSize; // px
      const dialogDivWidth = ((endTime - startTime) / (this.videoDuration)) * 100; // %
      const containerWidth = mainContainerWidth * (dialogDivWidth / 100);
      const textWidth = this.getTextWidth(text);
      const scaleX = (containerWidth / textWidth);
      return `transform: translate(-50%) scaleX(${scaleX})`;
    },
    checkIfBrowserIsChrome() {
      var isChromium = window.chrome,
        winNav = window.navigator,
        vendorName = winNav.vendor,
        isOpera = winNav.userAgent.indexOf("OPR") > -1,
        isIEedge = winNav.userAgent.indexOf("Edge") > -1,
        isIOSChrome = winNav.userAgent.match("CriOS");
      if (isIOSChrome) {
        // is Google Chrome on IOS
        return true;
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is Google Chrome
        return true;
      } else {
        // not Google Chrome 
        return false;
      }
    },
    getTextWidth(text) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.font = `${this.settings.fontSize}px ${this.settings.fontFamily}`;
      const metrics = ctx.measureText(text);
      return metrics.width;
    },
    calcWidth(startTime, endTime) {
      let width = ((endTime - startTime) / (this.videoDuration + this.offsetDuration)) * 100;
      return `width:${width}%`;
    },
    async initClientContentful() {
      const client = await this.$contentful.createClient({
        space: 'rlg2q6znunia',
        environment: 'master', // defaults to 'master' if not set
        accessToken: 'thSjjymf_lV7q9ZKmBrznLTRuzENiJBh12vc44YB6Uo'
      })
      return client;
    },
    makeIntro() {
      this.$nextTick(() => {
        if (localStorage.getItem('intro') == 'true')
          return;
        localStorage.setItem('intro', 'true');
        // introjs().start();
        // introjs(".intro1").start();
      })
    },
    initDraggableAudio() {
      var self = this;
      var duration = null
      var selectedSource = null;
      interact('.draggable-audio')
        .draggable({
          // enable inertial throwing
          inertia: true,
          // keep the element within the area of it's parent
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: 'parent',
              endOnly: true
            })
          ],
          // enable autoScroll
          autoScroll: true,
          listeners: {
            // call this function on every dragmove event
            move: dragMoveListener,
            // call this function on every dragend event
            end(event) {
              var textEl = event.target.querySelector('p')
              textEl && (textEl.textContent =
                'moved a distance of ' +
                (Math.sqrt(Math.pow(event.pageX - event.x0, 2) +
                  Math.pow(event.pageY - event.y0, 2) | 0))
                  .toFixed(2) + 'px')
            }
          }
        }).on('down', (event) => {
          // loop parents untel we get data-source
          let nameSource = event.target.getAttribute('data-source') || event.target.parentNode.getAttribute('data-source') || event.target.parentNode.parentNode.getAttribute('data-source') || event.target.parentNode.parentNode.parentNode.getAttribute('data-source');
          selectedSource = self.sources.find(source => source.name == nameSource);
          duration = selectedSource.start - selectedSource.end;
        })
      function dragMoveListener(event) {
        var target = event.target
        let totalWidth = (self.videoDuration * (25) * self.settings.fontSize)
        // keep the dragged position in the data-x is second on the video
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var id = target.getAttribute('data-id');
        // totalWidth en pixel
        self.sources.find(source => source.id == id).start = Math.round((parseFloat(((x / totalWidth) * self.videoDuration))) * 100) / 100
        self.sources.find(source => source.id == id).end = Math.round((parseFloat(self.sources.find(source => source.id == id).start) + parseFloat(duration)) * 100) / 100
        // translate the element
        // target.style.webkitTransform =
        //   target.style.transform =
        //   'translate(' + x + 'px)'
        // update the posiion attributes
        target.setAttribute('data-x', x)
        // target.setAttribute('data-y', y)
      }
      // this function is used later in the resizing and gesture demos
      window.dragMoveListener = dragMoveListener
    },
    initResizableWindow() {
      var self = this;
      interact('.resizable-layout').resizable({
        // resize from all edges and corners
        edges: { left: true, right: true },
        listeners: {
          move(event) {
            if (Math.floor(event.rect.width) % 10 === 0) {
              if (Math.floor(event.rect.width) < (window.innerWidth / 2)) {
                if (self.layoutoffset < -2) return;
                self.layoutoffset -= 1;
              } else {
                if (self.layoutoffset > 2) return;
                self.layoutoffset += 1;
              }
            }
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),
        ],
      })
    },
    initItemInteract() {
      var self = this;
      interact('.replace-item')
        .draggable({
          // enable inertial throwing
          inertia: true,
          // keep the element within the area of it's parent
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: 'parent',
              endOnly: true
            })
          ],
          // enable autoScroll
          autoScroll: true,
          listeners: {
            // call this function on every dragmove event
            move: function (event) {
              var target = event.target
              let id = target.getAttribute('data-id');
              let type = target.getAttribute('data-type');
              // keep the dragged position in the data-x/data-y attributes
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
              self.ndd[type].find(item => item.id == id).x = x;
              self.ndd[type].find(item => item.id == id).y = y;
              // translate the element
              target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
              // update the posiion attributes
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            },
          }
        })
    },
    initInteract() {
      var self = this;
      interact('.resize-drag')
        .resizable({
          // resize from all edges and corners
          edges: { left: true, right: true },
          listeners: {
            move(event) {
              var target = event.target
              var x = (parseFloat(target.getAttribute('data-x')) || 0)
              var y = (parseFloat(target.getAttribute('data-y')) || 0)
              if (!self.selectedSubtitle.id) return;
              let { left, right } = event.edges;
              // update the element's style
              target.style.width = event.rect.width + 'px'
              // largeur de la div en pixels
              let divWidth = event.rect.width;
              // durée totale de la timeline en secondes
              let timelineDuration = self.videoDuration;
              // temps de début du sous-titre en secondes
              let start = self.selectedSubtitle.start;
              // proportion de la timeline occupée par la div
              let proportion = divWidth / ((timelineDuration * (25) * self.settings.fontSize));
              // durée totale représentée par la div en secondes
              let duration = proportion * timelineDuration;
              // calcul de la valeur end en secondes
              let end = start + duration;
              if (right) {
                self.selectedSubtitle.end = parseFloat(self.selectedSubtitle.end);
                self.selectedSubtitle.end = parseFloat(end);
              }
              // translate when resizing from top or left edges
              x += event.deltaRect.left
              y += event.deltaRect.top
              if (left) {
                self.selectedSubtitle.start = parseFloat(self.selectedSubtitle.start);
                let start = parseFloat((event.deltaRect.left / ((timelineDuration * (25) * 20)) * timelineDuration));
                self.selectedSubtitle.start += start;
              }
              // target.style.transform = 'translate(' + x + 'px,' + y + 'px)'
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            }
          },
          modifiers: [
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
              outer: 'parent'
            }),
            // minimum size
            interact.modifiers.restrictSize({
              min: { width: 10, height: 50 }
            })
          ],
          // inertia: true
        })
        .draggable({
          listeners: {
            move(event) {
              var target = event.target
              var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
              var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
              // translate the element
              target.style.transform = 'translateY(' + y + 'px)'
              // update the posiion attributes
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
              if (!self.selectedSubtitle.id) return;
              self.selectedSubtitle.y = y;
              self.selectedSubtitle.start = parseFloat(self.selectedSubtitle.start);
              self.selectedSubtitle.end = parseFloat(self.selectedSubtitle.end);
              let start = parseFloat((event.dx / ((self.videoDuration * (25) * self.settings.fontSize)) * self.videoDuration));
              let end = parseFloat((event.dx / ((self.videoDuration * (25) * self.settings.fontSize)) * self.videoDuration));
              self.selectedSubtitle.start += start;
              self.selectedSubtitle.end += end;
            },
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: 'timeline-cell',
                endOnly: true
              })
            ]
          },
          inertia: false
        })
        .on('down', (event) => {
          let { id } = event.target;
          if (!id) {
            id = event.target.parentNode.id;
            if (!id) {
              id = event.target.parentNode.parentNode.id;
              if (!id) {
                id = event.target.parentNode.parentNode.parentNode.id;
              }
            }
          }
          self.selectedSubtitle = self.subtitles.find(sub => sub.id == id);
          self.isEditingSubtitle = true;
        })
        .on('resizeend', () => {
          self.isEditingSubtitle = false;
        })
        .on('up', () => {
        }).on('load', (event) => {
          console.log(event)
        })
    },
    initInteractLetter() {
      interact('.resize-drag-letter')
        .resizable({
          // resize from all edges and corners
          edges: { right: true },
          listeners: {
            move(event) {
              var target = event.target
              var x = (parseFloat(target.getAttribute('data-x')) || 0)
              var y = (parseFloat(target.getAttribute('data-y')) || 0)
              // translate when resizing from top or left edges
              x += event.deltaRect.left
              y += event.deltaRect.top
              target.style.transform = 'scaleX(' + event.rect.width / 100 + ')'
              target.setAttribute('data-x', x)
              target.setAttribute('data-y', y)
            }
          },
          modifiers: [
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
              outer: 'parent'
            }),
            // minimum size
            interact.modifiers.restrictSize({
              min: { width: 20, height: 50 }
            })
          ],
          inertia: true
        })
        .on('down', (event) => {
          console.log(event)
        })
    },
    playVideo() {
      this.$refs.video.paused ? this.$refs.video.play() : this.$refs.video.pause();
      this.videoPaused = this.$refs.video.paused;
      // filter the source.start / end are on the range of currentTime
      let sourcesAudio = this.sources.filter(source => source.start <= this.$refs.video.currentTime && source.end >= this.$refs.video.currentTime);
      for (const source of sourcesAudio) {
        if (!this.$refs[`audio${source.character}`]) continue
        this.videoPaused ? !this.$refs[`audio${source.character}`][0].paused ? this.$refs[`audio${source.character}`][0].pause() : false : this.$refs[`audio${source.character}`][0].play(this.$refs.video.currentTime - source.start);
      }
    },
  },
  mounted() {
    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight
    this.tasksInterval = setInterval(() => {
      if (!this.taskPopup) return;
      this.getTasks();
    }, 5000)
    this.tipInterval = setInterval(() => {
      if (this.saveInProgress) {
        this.tips.map(tip => tip.show = false)
        this.tips[Math.floor(Math.random() * this.tips.length)].show = true;
      }
    }, 10000)
    if (this.$vuetify.breakpoint.mobile) {
      this.cinemaMode = true;
      this.$nextTick(() => {
        this.selectedSubtitle = { id: null };
      })
    }
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
    })
    window.html2canvas = html2canvas;
    // prevent window leave
    window.onbeforeunload = function () {
      return "Are you sure you want to leave?";
    }
    this.$nextTick(() => {
      this.initInteract();
      this.initInteractLetter();
      this.initResizableWindow()
      this.initDraggableAudio();
      this.initItemInteract();
    })
    // add event listener mouse scroll
    document.addEventListener('wheel', (e) => {
      // prevent when text input is focused
      if (document.activeElement.tagName == 'INPUT') return;
      // if press ctrl or cmd + mouse scroll
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        // if scroll up, increase video time
        if (e.deltaY < 0 || e.deltaX < 0) {
          this.$refs.video.currentTime += 1 / 25;
        }
        // if scroll down, decrease video time
        if (e.deltaY > 0 || e.deltaX > 0) {
          this.$refs.video.currentTime -= 1 / 25;
        }
      }
    })
    // if press space bar, play/pause video
    document.addEventListener('keydown', (e) => {
      // prevent when text input is focused
      if (!this.$refs.video) return;
      if (this.renderDone) return;
      if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'DIV' || document.activeElement.tagName == 'TEXTAREA') return;
      if (e.keyCode == 32) {
        e.preventDefault();
        // unfocus video
        if (document.activeElement.tagName == 'VIDEO') {
          if (this.$refs.video) {
            this.$refs.video.blur();
          }
        }
        if (!this.$refs.video) return;
        this.playVideo();
      }
      // undo operation
      if ((e.keyCode == 90 && e.ctrlKey) || (e.keyCode == 90 && e.metaKey)) {
        e.preventDefault();
        this.undo();
      }
      // if press right arrow and shift key, increase video time
      if (e.keyCode == 39 && e.shiftKey) {
        e.preventDefault();
        this.$refs.video.currentTime += 1;
      }
      // if press left arrow and shift key, decrease video time
      if (e.keyCode == 37 && e.shiftKey) {
        e.preventDefault();
        this.$refs.video.currentTime -= 1;
      }
      // if press right arrow, increase video time
      if (e.keyCode == 39) {
        e.preventDefault();
        this.$refs.video.currentTime += 1 / 25;
        // play video audio source on the range of currentTime
        // play one frame and stop
        if(!this.$refs.video.paused) return;
        try{
          this.$refs.video.play();
        }catch(err){
          console.log(err)
        }
        setTimeout(() => {
          try{
            this.$refs.video.pause();
          }catch(err)
          {
            console.log(err)
          }
        }, 100)
      }
      // if press left arrow, decrease video time
      if (e.keyCode == 37) {
        e.preventDefault();
        this.$refs.video.currentTime -= 1 / 25;
        // play video audio source on the range of currentTime
        // play one frame and stop
        if(!this.$refs.video.paused) return;
        try{
          // this.$refs.video.play();
        }catch(err)
        {
          console.log(err)
        }
        setTimeout(() => {
          try{
            // this.$refs.video.pause();
          }catch(err)
          {
            console.log(err)
          }
        }, 100)
      }
      // CTRL or CMD +
      if (e.keyCode == 187 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.createSubtitle('Text here');
        this.selectedSubtitle = this.subtitles[this.subtitles.length - 1];
        this.scrollTo(this.subtitles.length - 1);
      }
      // CMD + S or CTRL + S
      if ((e.keyCode == 83 && e.metaKey) || (e.keyCode == 83 && e.ctrlKey)) {
        e.preventDefault();
        this.saveProject();
      }
      // CMD OR CTRL + K 
      if ((e.keyCode == 75 && e.metaKey) || (e.keyCode == 75 && e.ctrlKey)) {
        e.preventDefault();
        if (!this.isRecording) {
          this.recordAudio(this.selectedCharacter)
        } else {
          this.stopRecording()
        }
      }
    })
    document.addEventListener('click', (e) => {
      if (!this.$refs.offsetDiv) return;
      let scroll = (e.x / this.$refs.offsetDiv.scrollWidth) * this.videoDuration * 25;
      let time = scroll + this.videoTime
      this.cursorTime = parseFloat(time)
    });
    this.makeIntro();
    this.workspace = this.$route.query.workspace || 40;
    this.loadWorkspace();
    this.getTasks();
    this.$nextTick(() => {
      this.$refs.video.addEventListener('pause', () => {
        if (this.isRecording) {
          this.stopRecording();
        }
      })
    })
  },
  beforeDestroy() {
    clearInterval(this.tasksInterval);
    clearInterval(this.tipInterval);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.confirmLeave) {
      this.confirmLeavePopup = true;
      this.nextRoute = to;
    } else {
      next();
    }
  },
  watch: {
    cinemaMode() {
      this.$store.dispatch('setCinemaMode', this.cinemaMode);
    },
    videoSpeedRate() {
      this.$refs.video.playbackRate = this.videoSpeedRate;
    },
    bottomTab() {
      //  get all wave surfer
      this.$nextTick(() => {
        for (let source of this.sources) {
          if (this.$refs[`audio${source.character}${source.id}`]) {
            setTimeout(() => {
              this.$refs[`audio${source.character}${source.id}`][0].wavesurfer.drawBuffer()
            }, 1000)
          }
        }
      })
    },
    selectedSubtitle: {
      deep: true,
      handler() {
        this.selectedCharacter = this.selectedSubtitle.character
      }
    },
    selectedCharacter: {
      deep: true,
      handler() {
        this.selectedSubtitle.character = this.selectedCharacter
      }
    },
    subtitles: {
      deep: true,
      handler() {
        this.subtitles.forEach((subtitle, index) => {
          subtitle.id = index + 1;
        })
      },
    },
    "settings.height": {
      deep: true,
      handler() {
        console.log('change height')
        this.subtitles.forEach((subtitle) => {
          if (subtitle.y > (this.settings.height - 50)) {
            subtitle.y = this.settings.height - 50;
          }
        })
        this.$forceUpdate();
      }
    },
  }
}
</script>
<style lang="scss">
// .timeline-cell {
//   height: 50px;
// }
.subtitle-dialog {
  box-shadow: none;
  transition: box-shadow 0.3s ease;
}
.subtitle-dialog:hover {
  transition: box-shadow 0.3s ease;
  box-shadow: inset 20px 0px 0px 0px #58e2794d, inset -20px 0px 0px 0px #6449D178;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Sacramento&family=Oooh+Baby&family=The+Girl+Next+Door&display=swap');
@font-face {
  src: url('@/font/LeagueMono-VF.ttf') format('truetype');
  font-family: League;
  font-style: normal;
  font-weight: 400;
  font-stretch: 50% 200%
}
#output section {
  font-size: 1.2em;
  font-family: League, sans-serif
}
.v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
  padding-left: 0px;
}
video {
  max-height: 100%;
  margin: auto;
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
  position: relative;
}
.introjs-tooltiptext {
  font-family: 'Open Sans';
}
a.introjs-button,
.introjs-button:focus,
.introjs-button:active {
  // primary var color
  background-color: #6449D1 !important;
  color: white;
  border: none;
  text-shadow: none;
  font-family: 'Open Sans';
  background-image: none;
}
.introjs-button:focus,
.introjs-button:active {
  background-image: none !important;
}
.introjs-disabled {
  background-color: #373737;
}
// .ws and child elements
wave,
.ws * {
  touch-action: none !important;
}
#divOffset::-webkit-scrollbar {
  display: none;
}
#divOffset,
header {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.dialogue {
  touch-action: none !important;
  // cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.col::-webkit-scrollbar,
div::-webkit-scrollbar,
header::-webkit-scrollbar {
  display: none;
}
.col,
div {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.menu {
  position: relative;
  flex: 0 1 7% !important;
  z-index: 2;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-color: rgb(28, 31, 47);
}
.workspace {
  flex: 2 1 35%;
  z-index: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.rythmospace {
  position: relative;
  z-index: 0;
  resize: vertical;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 150px;
}
// Flex
.fullscreen {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px) !important;
  overflow: hidden;
}
.top {
  position: relative;
  z-index: 2;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-color: rgb(28, 31, 47);
}
.center {
  flex: 1 1 0%;
  z-index: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid rgb(0, 0, 0);
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.bottom {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  // height: 150px;
  overflow: hidden;
}
.left {
  height: 100%;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  border-right: 1px solid rgb(0, 0, 0);
}
.right {
  height: 100%;
  min-width: 60vw;
  display: flex;
}
.subtitle {
  box-sizing: border-box;
  direction: ltr;
  position: relative;
  will-change: transform;
  overflow: hidden auto;
}
.videocanvas {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.art-video-player {
  z-index: 20;
  width: 100%;
  height: 100%;
  zoom: 1;
  color: #eee;
  text-align: left;
  direction: ltr;
  user-select: none;
  -webkit-tap-highlight-color: #0000;
  touch-action: manipulation;
  -ms-high-contrast-adjust: none;
  background-color: #000;
  outline: 0;
  margin: 0 auto;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.3;
  display: flex;
  position: relative;
}
.rythmoTabActive {
  display: initial !important;
  position: absolute;
  width: 100%;
}
.videocontainer {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex: 1 1 0%;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
}
// fade transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.waveform {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}
.waveform:hover {
  opacity: .5;
  transition: opacity .3s ease-in-out;
}
.resize-drag {
  touch-action: none !important;
}
.containerapp {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.column1 {
  flex: 4;
  display: flex;
  justify-content: center;
  /* Centrer le contenu horizontalement */
  align-items: center;
}
.column2 {
  flex: 6;
  overflow: auto;
}
.rowbottom {
  display: flex;
  flex-direction: column;
}
.column3 {
  flex: 1;
}
.v-menu__content.theme--dark.menuable__content__active {
  z-index: 10000 !important;
}
.v-dialog {
  z-index: 1000 !important;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.fixed-bar {
  position: fixed;
  width: auto;
  position: -webkit-sticky;
  /* for Safari */
  // top: 0em;
  z-index: 2;
}
.rythmo-ruban {
  background-image: url('@/assets/background-ticker.png');
  background-repeat: repeat-x;
}

.base-audio-wave {
  width:100%;
  position:absolute;
  z-index:100000;
}
</style>